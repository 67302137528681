import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'
import { MuiThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider } from "styled-components"
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import ResponsiveDrawer from './components/ResponsiveDrawer'
import Footer from './components/Footer'
import LastSeasonDialogue from './components/LastSeasonDialogue'
import GenericAlert from './components/GenericAlert'
import MainGrid from './components/MainGrid'
import LoginBackdrop from './components/LoginBackdrop'
import Header from './components/Header'
import Login from './components/Login'
import { launchLogin, receiveJWT, checkLogin, initialLoginState } from './authScripts'
import { getPlayers } from './restCalls'
import UserProfile from './components/UserProfile'
import LogoutDialog from './components/LogoutDialog'
import CssBaseline from '@material-ui/core/CssBaseline'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DataStatementContent from './components/DataStatementContent'
import Snackbar from '@material-ui/core/Snackbar'
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { initialTeam } from './util'


// // Main Theme
// const theme = createMuiTheme({
//   palette: {
//     //type: prefersDarkMode ? 'dark' : 'light',
//     primary: {
//       main: "#b71c1c"
//     },
//     secondary: {
//       main: "#1b5e20"
//     }
//   },
//   typography: {
//     htmlFontSize: 18
//   },
// });


const App = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: "#b71c1c",
            //main: "#f44336"
          },
          secondary: {
            main: "#1b5e20",
            //main: "#ffc107"
          }
        },
        typography: {
          htmlFontSize: 18
        },
        // overrides: {
        //   MuiCssBaseline: {
        //     "@global": {
        //       html: {
        //         fontSize: "55%"
        //       }
        //     }
        //   }
        // }
      }),
    [prefersDarkMode],
  )


  // set up the payment button settings
  const initialOptions = {
    "client-id": `${process.env.REACT_APP_STAGE === process.env.REACT_APP_NAME_OF_PROD_STAGE ?
      process.env.REACT_APP_PAYPAL_CLIENT_PROD :
      process.env.REACT_APP_PAYPAL_CLIENT_NONPROD}`,
    currency: "EUR",
    intent: "capture",
    locale: "de_DE",
    "disable-funding": "credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo"
  }

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0, 0, 0)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    '@global': {
      '.paper': {
        padding: theme.spacing(3),
        height: "100%",
        width: "100%",
      },
    },
  }))
  const classes = useStyles()


  ///////////////////////////////
  //FUNKTIONS
  ///////////////////////////////

  // setting the reference to the loginStatus for use in the timer
  const loginStatusRef = useRef(initialLoginState())
  useEffect(() => {
    loginStatusRef.current = loginStatus
  })

  const receiveMessage = (event) => {
    receiveJWT(event, loginStatusRef.current, setLoginStatus, setRedirectStatus)
  }

  //Create event listener on render for receiving the JWT and start timer for login check
  useEffect(() => {
    // add event listener
    window.addEventListener('message', receiveMessage)

    // check login now
    checkLogin(setLoginStatus, loginStatusRef.current, setAutoLogoutStatus)
    // set timer for checking login status
    const timerId = setInterval(() => {
      checkLogin(setLoginStatus, loginStatusRef.current, setAutoLogoutStatus)
    }, 60000)

    // cleanup event listener and timer
    return () => {
      window.removeEventListener('message', receiveMessage)
      clearTimeout(timerId)
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // load the players & remember date
  const initPlayers = async () => {
    const players = await getPlayers()
    setPlayerList(players)
    setLoadPlayerDate(new Date().toLocaleDateString())
  }

  //sleep
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  ///////////////////////////////
  //states
  ///////////////////////////////

  // settings
  const initialSetting = {
    desiredTeamSize: 11,
    keeperRange: [1, 1],
    defenderRange: [4, 6],
    midfielderRange: [4, 6],
    forwardRange: [2, 4],
    autoSetPlayerCountRanges: true,
    desiredFormation: { "GOALKEEPER": 1, "DEFENDER": 4, "MIDFIELDER": 4, "FORWARD": 2 },
    desiredFormationKey: 442,
    excludePlayers: [],
    includePlayers: [],
    excludePlayersFull: [],
    includePlayersFull: [],
    addSubstituteKeepers: true,
    fillUpSquadWithBestPlayers: false,
    maxMvByPosition: { "GOALKEEPER": 8.5, "DEFENDER": 8.5, "MIDFIELDER": 8.5, "FORWARD": 8.5 },
    maxPlayersPerClub: 22,

    wCurrentMarketValue: 0,
    wLastSeasonPoints: 4,
    wLastSeasonAvgGrade: 3,
    wTeamRating: 3,
    wLastSeasonAppear: 2,
    wStartingChance: 0.6,
    wLastSeasonGoals: 2,
    wTeamRatingKeeper: 1,

    wSubCurrentMarketValue: 0,
    wSubLastSeasonPoints: 2,
    wSubLastSeasonAvgGrade: 3,
    wSubTeamRating: 1,
    wSubLastSeasonAppear: 3,
    wSubStartingChance: 0.6,
    wSubLastSeasonGoals: 1,
    wSubTeamRatingKeeper: 1,
  }
  const [settings, setSettings] = useState(initialSetting)

  // team
  const [team, setTeam] = useState(initialTeam)

  // load status
  const [loadStatus, setLoadStatus] = useState({ progress: 1, fetched: true })

  // last player update
  const [loadPlayerDate, setLoadPlayerDate] = useState(null)

  // LogoutDialogue state
  const [autoLogoutStatus, setAutoLogoutStatus] = useState(false)

  // login status
  const [loginStatus, setLoginStatus] = useState(initialLoginState())

  // the player list
  const [playerList, setPlayerList] = useState([])

  // the mobile drawer state
  const [mobileOpen, setMobileOpen] = useState(false)

  // show istructions
  const [instructionVisible, setInstructionVisible] = useState(true)

  // LastSeason dialogue
  const [lastSeasonDialogueStatus, setLastSeasonDialogueStatus] = useState(false)

  // Agb checkbox
  const [agbCheckStatus, setAgbCheckStatus] = useState(false)

  // The redirect status
  const [redirectStatus, setRedirectStatus] = useState(null)

  // The expand menu effect status
  const [expandMenuEffectStatus, setExpandMenuEffectStatus] = useState({ desktop: false, mobile: false, down: false })

  // Generic Alert
  const [genericAlertStatus, setGenericAlertStatus] = useState({ open: false, title: "", body: "", button: "" })

  // The optimization counter status
  const [optiCounterStatus, setOptiCounterStatus] = useState(0)

  // The snackbar status
  const [snackBarStatus, setSnackBarStatus] = useState({ open: false, duration: 5000, message: "" })

  // Stats Tooltipp
  const [statsOpen, setStatsOpen] = React.useState(false)

  //Increase the opti counter and show snackbar if necessary
  const increaseOptiCounter = () => {
    const newValue = optiCounterStatus + 1
    setOptiCounterStatus(newValue)

    // only for non pro users
    if (!loginStatus.pro) {
      // Pro reminder
      if ([4, 25, 60].includes(newValue)) {
        setSnackBarStatus({ open: true, duration: 6000, message: "Logge dich ein und hole dir die erweiterten Pro-Features - für ein noch besseres Team!" })
      }
      if ([10].includes(newValue)) {
        setSnackBarStatus({ open: true, duration: 6000, message: "Du willst einzelne Spieler unbedingt ins Team holen und die restliche Mannschaft um sie aufbauen? Logge dich ein und hole dir Pro-Features wie die Spielerauswahl oder den Stammspielerregler!" })
      }

      // Settings reminder
      if (settings.wCurrentMarketValue === initialSetting.wCurrentMarketValue &&
        settings.wLastSeasonPoints === initialSetting.wLastSeasonPoints &&
        settings.wLastSeasonAvgGrade === initialSetting.wLastSeasonAvgGrade &&
        settings.wTeamRating === initialSetting.wTeamRating &&
        settings.wLastSeasonAppear === initialSetting.wLastSeasonAppear &&
        settings.wStartingChance === initialSetting.wStartingChance &&
        settings.wLastSeasonGoals === initialSetting.wLastSeasonGoals &&
        settings.wTeamRatingKeeper === initialSetting.wTeamRatingKeeper) {
        if ([2, 15].includes(newValue)) {
          setSnackBarStatus({ open: true, duration: 6000, message: "Du kannst die vielfältigen Einstellungen im Menü nutzen, um dein Team nach deinen Vorstellungen zu formen." })
        }
        if ([7].includes(newValue)) {
          setSnackBarStatus({ open: true, duration: 6000, message: "Über die Einstellungen im Menü kannst du beispielsweise festlegen, ob dir eine gute Durchschnittsnote oder viele Tore in der letzten Saison wichtiger für deine Spieler sind." })
        }
      }
    }

  }

  //handle snackbar close
  const handleSnackbarClose = () => {
    setSnackBarStatus({ ...setSnackBarStatus, open: false, })
  }

  // on optimization we check if we want to update the players once a day
  useEffect(() => {
    if (new Date().toLocaleDateString() !== loadPlayerDate) {
      // get player list
      initPlayers()
    }
  }, [loadStatus])

  //play the expand menu effect
  const playExpandMenuEffect = async (mobile) => {
    await sleep(1500)
    setExpandMenuEffectStatus({ desktop: true, mobile: mobile, down: true })
    await sleep(100)
    setExpandMenuEffectStatus({ desktop: true, mobile: mobile, down: false })
    await sleep(200)
    setExpandMenuEffectStatus({ desktop: true, mobile: mobile, down: true })
    await sleep(100)
    setExpandMenuEffectStatus({ desktop: true, mobile: mobile, down: false })
  }

  // hook for playing the expand menu effect
  useEffect(() => {
    if (!expandMenuEffectStatus.desktop) {
      playExpandMenuEffect(false)
    }
    if (!expandMenuEffectStatus.mobile && mobileOpen) {
      playExpandMenuEffect(true)
    }
  }, [mobileOpen])

  ///////////////////////////////
  // RETURN
  ///////////////////////////////

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <PayPalScriptProvider options={initialOptions}>
          <CssBaseline />
          {/* Full frame div */}
          <div className={classes.root}>
            <LastSeasonDialogue lastSeasonDialogueStatus={lastSeasonDialogueStatus} setLastSeasonDialogueStatus={setLastSeasonDialogueStatus}></LastSeasonDialogue>
            <Snackbar open={snackBarStatus.open} message={snackBarStatus.message} onClose={handleSnackbarClose} />
            <GenericAlert genericAlertStatus={genericAlertStatus} setGenericAlertStatus={setGenericAlertStatus} />
            <LoginBackdrop loginStatus={loginStatus} setLoginStatus={setLoginStatus} />
            <LogoutDialog loginStatus={loginStatus} setLoginStatus={setLoginStatus} autoLogoutStatus={autoLogoutStatus} setAutoLogoutStatus={setAutoLogoutStatus} launchLogin={launchLogin} />
            {/* Top level container */}
            <Container maxWidth="md" className={classes.root}>
              <Router>
                <Routes>
                  <Route path='/' exact element={
                    <>
                      <Header launchLogin={launchLogin} loginStatus={loginStatus} setLoginStatus={setLoginStatus} handleDrawerToggle={handleDrawerToggle} showMenuIcon={true} />
                      <MainGrid settings={settings} setSettings={setSettings} team={team} setTeam={setTeam} loadStatus={loadStatus} setLoadStatus={setLoadStatus} loginStatus={loginStatus} setLoginStatus={setLoginStatus} playerList={playerList} setInstructionVisible={setInstructionVisible} instructionVisible={instructionVisible} setGenericAlertStatus={setGenericAlertStatus} redirectStatus={redirectStatus} setRedirectStatus={setRedirectStatus} expandMenuEffectStatus={expandMenuEffectStatus} increaseOptiCounter={increaseOptiCounter} statsOpen={statsOpen} setStatsOpen={setStatsOpen} />
                      <ResponsiveDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} settings={settings} setSettings={setSettings} loginStatus={loginStatus} playerList={playerList} expandMenuEffectStatus={expandMenuEffectStatus} />
                      <Footer />
                    </>
                  } />
                  <Route path='/login' Component={Login} />
                  <Route path='/profile' element={
                    loginStatus.loggedIn ?
                      <>
                        <Header launchLogin={launchLogin} loginStatus={loginStatus} setLoginStatus={setLoginStatus} handleDrawerToggle={handleDrawerToggle} showMenuIcon={false} />
                        <UserProfile loginStatus={loginStatus} setLoginStatus={setLoginStatus} agbCheckStatus={agbCheckStatus} setAgbCheckStatus={setAgbCheckStatus} setGenericAlertStatus={setGenericAlertStatus} />
                        <Footer />
                      </>
                      : <Navigate to="/" />
                  } />
                  <Route path='/dataprivacy' Component={DataStatementContent} />
                </Routes>
              </Router>
            </Container>

          </div>
        </PayPalScriptProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App
