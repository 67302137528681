import { fetchPlus } from './restCalls'

//parse the Jwt
export function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
};



//launch the cognito hosted ui
export const launchLogin = (setLoginStatus, loginStatus) => {
  //console.log("launchLogin called")
  // get the current path
  var redicrectUrl = window.location.href
  // remove the potential subroutes defined in REACT_APP_SUBROUTES to get the pure host
  process.env.REACT_APP_SUBROUTES.split(',').forEach((route) => { redicrectUrl = window.location.href.replace(route, "") })
  const loginUrl = `${process.env.REACT_APP_LOGIN_UI}?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${redicrectUrl}login&response_type=token&scope=email+openid+profile`
  setLoginStatus({ ...loginStatus, loginOngoing: true })
  var windowFeatures = "menubar=yes,resizable=yes,scrollbars=yes,status=yes"
  window.open(loginUrl, "Login", windowFeatures)
}



// perform user logout
export const performLogout = (setLoginStatus, loginStatus) => {
  setLoginStatus(initialLoginState())
  localStorage.removeItem('loginStatus')
}



//compare the origin of the event source and the current path
export const sameOrigin = (event) => {
  // get the current path
  var currentURL = window.location.href
  // remove the potential subroutes defined in REACT_APP_SUBROUTES to get the pure host
  process.env.REACT_APP_SUBROUTES.split(',').forEach((route) => { currentURL = window.location.href.replace(route, "") })
  // remove potential trailing slashes for comparison
  const href = currentURL.slice(-1) === "/" ? currentURL.slice(0, -1) : currentURL
  const origin = event.origin.slice(-1) === "/" ? event.origin.slice(0, -1) : event.origin
  return href === origin
}


//check if user has subscription
export const checkSubscription = (userObject) => {
  var pro = false
  try {
    const subscriptions = JSON.parse(userObject["custom:subscriptions"])
    if (subscriptions.indexOf(process.env.REACT_APP_CURRENT_SUBSCRIPTION_NAME) > -1) {
      pro = true
    }
  } catch (err) { return }
  return pro
}


// handle received JWT via postMessage
export const receiveJWT = (event, loginStatus, setLoginStatus, setRedirectStatus) => {
  //return if the message is not for us
  if (!sameOrigin(event))
    return

  // check if we received an id_token, set login state and store state
  try {
    const parsedTokens = JSON.parse(event.data)
    const parsedIdToken = parseJwt(parsedTokens.idToken)
    const accessToken = parsedTokens.accessToken
    //console.log(parsedIdToken)

    //check if user has subscription
    const pro = checkSubscription(parsedIdToken)

    //set login status & store to localStorage
    const logStat = {
      ...loginStatus, idToken: parsedTokens.idToken, email: parsedIdToken.email,
      userId: parsedIdToken["cognito:username"], exp: parsedIdToken.exp,
      loggedIn: true, loginOngoing: false, pro: pro, accessToken: accessToken
    }
    setLoginStatus(logStat)
    localStorage.setItem('loginStatus', JSON.stringify(logStat))

    // Google Analytics
    //window.gtag("event", "login", { method: "aws_hosted_ui" })

    if (!pro) setRedirectStatus("/profile")
  } catch (err) { return }
}


// check login status
export const checkLogin = (setLoginStatus, loginStatus, setAutoLogoutStatus) => {
  //console.log("prevLoginStatus: ", loginStatus)
  var statusToCheck = null

  // if user is logged in according to state, we use the state for checks
  if (loginStatus.loggedIn) {
    statusToCheck = loginStatus
  } else { //otherwise we try to get the state from storage
    if (localStorage.getItem('loginStatus') !== null) {
      // get loginStatus from storage
      statusToCheck = JSON.parse(localStorage.getItem('loginStatus'))
    }
  }

  //if we don't find any status we can return
  if (statusToCheck === null) {
    return false
  }

  //check for expiration of the status we found
  // we log the user out a bit earlier to make sure he can still finish payment steps, etc...
  const bufferSeconds = process.env.REACT_APP_MIN_REQUIRED_TOKEN_LIFETIME_MINUTES * 60
  const expWithBufferSeconds = statusToCheck.exp - bufferSeconds
  //console.log('Seconds till exp: ', expWithBufferSeconds - Date.now() / 1000)

  // check if expired
  if (Date.now() >= expWithBufferSeconds * 1000) {
    performLogout(setLoginStatus, loginStatus)
    setAutoLogoutStatus(true)
    return false
  }

  // set status from storage only if user was not logged in according to state
  if (!loginStatus.loggedIn) { setLoginStatus(statusToCheck) }

  return true
}



// return the initial login state
export const initialLoginState = () => {
  return {
    idToken: null, accessToken: null, email: null, userId: null, exp: null, loggedIn: false, loginOngoing: false, nextTarget: '/', pro: false
  }
}



//refresh user info
export const getUserInfo = async (loginStatus, setLoginStatus) => {

  const res = await fetchPlus(process.env.REACT_APP_USERINFO_URL,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${loginStatus.accessToken}`
      }
    }, 3)

  const data = await res.json()
  //console.log(res.status, data)
  if (res.status === 200) {
    setLoginStatus({ ...loginStatus, pro: checkSubscription(data) })
    localStorage.setItem('loginStatus', JSON.stringify({ ...loginStatus, pro: checkSubscription(data) }))
    return true
  } else {
    return false
  }

}