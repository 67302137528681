import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function LoginBackdrop({ loginStatus, setLoginStatus }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      alignContent: "center"
    },
    cancel: {
      cursor: "pointer",
      textDecoration: "underline",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center"
    },
  }));
  const classes = useStyles();

  const handleAbort = () => {
    setLoginStatus({ ...loginStatus, loginOngoing: false })
  };

  return (
    <Backdrop className={classes.backdrop} open={loginStatus.loginOngoing}>
      <div className={classes.container}>
        <Typography variant="h6" >
          Warten auf Login
          <div onClick={handleAbort} className={classes.cancel}>Abbrechen</div>
        </Typography>
      </div>
    </Backdrop>
  )
}

export default LoginBackdrop
