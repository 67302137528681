import React from 'react'
import { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Pitch from './Pitch'
import PitchHeader from './PitchHeader'
import Substitutes from './Substitutes'
import DrawerContent from './DrawerContent'
import Hidden from '@material-ui/core/Hidden'
import { getUserInfo } from '../authScripts'
import { useNavigate } from "react-router-dom"

function MainGrid({ settings, setSettings, team, setTeam, loadStatus, setLoadStatus, loginStatus, setLoginStatus, playerList, setInstructionVisible, instructionVisible, setGenericAlertStatus, redirectStatus, setRedirectStatus, expandMenuEffectStatus, increaseOptiCounter, statsOpen, setStatsOpen }) {


  // Style
  const useStyles = makeStyles((theme) => ({

    gridcontainer: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: "0",
      overflow: "hidden"
    },
    gridItem: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    settingsContainer: {
      padding: theme.spacing(0, 2, 2, 2),
      margin: theme.spacing(1.5, 0, 0),
    },
  }))
  const classes = useStyles()

  // on load get the up to date data from the user endpoint
  useEffect(() => {
    getUserInfo(loginStatus, setLoginStatus)
  }, [])


  const navigate = useNavigate()
  //This is called when we want to do a redirect
  useEffect(() => {
    if (redirectStatus) {
      navigate(redirectStatus)
      setRedirectStatus(null)
    }
  }, [redirectStatus])

  return (

    <div className={classes.gridcontainer}>

      <Grid container spacing={0}>
        <Grid item xs={false} sm={false} md={3} >
          <Hidden smDown implementation="css">
            <div className={classes.settingsContainer}>
              <DrawerContent settings={settings} setSettings={setSettings} loginStatus={loginStatus} playerList={playerList} expandMenuEffectStatus={expandMenuEffectStatus}></DrawerContent>
            </div>
          </Hidden>
        </Grid>

        <Grid item xs={12} sm={8} md={6}>
          <div className={classes.gridItem}>
            <PitchHeader settings={settings} setSettings={setSettings} setTeam={setTeam} loadStatus={loadStatus} setLoadStatus={setLoadStatus} loginStatus={loginStatus} setInstructionVisible={setInstructionVisible} setGenericAlertStatus={setGenericAlertStatus} increaseOptiCounter={increaseOptiCounter}></PitchHeader>
            <Pitch team={team} instructionVisible={instructionVisible} playerList={playerList} statsOpen={statsOpen} setStatsOpen={setStatsOpen}></Pitch>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={3} >
          <div className={classes.gridItem}>
            <Substitutes team={team}></Substitutes>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default MainGrid


