import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import { BsPersonCheckFill, BsFillPersonDashFill } from "react-icons/bs"
import { FaUserSecret, FaExchangeAlt, FaCheckCircle } from "react-icons/fa" //FaCreditCard, FaPaypal,
import { BiSliderAlt, BiArrowBack } from "react-icons/bi"
import { RiMoneyEuroCircleFill } from "react-icons/ri"
import { GiSoccerField } from "react-icons/gi"
import Payment from './Payment'
import Feature from './Feature'
import { getUserInfo } from '../authScripts'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { useNavigate } from "react-router-dom"

function UserProfile({ loginStatus, setLoginStatus, agbCheckStatus, setAgbCheckStatus, setGenericAlertStatus }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(2, 0, 0),
    },
    featureBox: {
      margin: theme.spacing(1, 0, 2),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "flex-start",
    },
    checkListItem: {
      margin: theme.spacing(0.5, 0, 0),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
    },
    checkListIcon: {
      margin: theme.spacing(0, 0.5, 0, 0),
    },
    avatar: {
      margin: theme.spacing(0, 2, 0, 0),
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    icon: {
      color: "inherit",
      fontSize: "inherit",
    },
    floatLeft: {
      width: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center"
    },
    floatCenter: {
      width: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center"
    },
    floatRight: {
      width: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      alignContent: "center"
    },
    header: {
      width: "100%",
      margin: theme.spacing(0, 0, 3),
      display: "flex",
    },
    ticket: {
      width: "100%",
      margin: theme.spacing(2, 0, 0),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center",
    },
    payment: {
      width: "100%",
      margin: theme.spacing(2, 0, 0),
    },
    gridItem: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    divider: {
      width: "100%",
      margin: theme.spacing(1, 0, 1),
    },
  }))
  const classes = useStyles()

  ///////////////////////////////
  //FUNKTIONS
  ///////////////////////////////

  // on load get the up to date data from the user endpoint
  useEffect(() => {
    getUserInfo(loginStatus, setLoginStatus)
  }, [])

  const navigate = useNavigate()


  ///////////////////////////////
  // RETURN
  ///////////////////////////////

  return (
    <div className={classes.root}>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className={classes.gridItem}>
            <div className={classes.header}>
              <div className={classes.floatLeft}>
                <IconButton onClick={() => { navigate("/") }}>
                  <BiArrowBack />
                </IconButton>
              </div>
              <div className={classes.floatCenter}>
                <div>
                  <Badge badgeContent={"pro"} color="secondary" invisible={!loginStatus.pro} overlap="circle" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Avatar className={classes.avatar}>{loginStatus.email.slice(0, 2)}</Avatar>
                  </Badge>
                </div>
                <div> <Typography variant="h6" >{loginStatus.email}</Typography></div>
              </div>
              <div className={classes.floatRight}>

              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.gridItem}>
            <Typography variant="h5" >
              {!loginStatus.pro ? "Hole dir hier die Pro-Version! Deine Vorteile:" : "Deine Vorteile der Pro-Version:"}
            </Typography>
            <Divider className={classes.divider} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.gridItem}>
            <div className={classes.featureBox}>
              <Feature title={"Stammspieler-Regler"} subtext={"Hole dir automatisch die Spieler, die wahrscheinlich Stammspieler sind. Die Einschätzung stammt unter anderem von Experten wie Ligainsider und später auch aus der DFB-Pokal Startelf."}><GiSoccerField className={classes.icon} /></Feature>
            </div>
            <div className={classes.featureBox}>
              <Feature title={"Aktueller Marktwert"} subtext={"Richte dein Team nach dem aktuellen Marktwert aus, welcher die Leistungen in der Vorbereitung und das Know-How der besten deutschen Manager widerspiegelt."}><RiMoneyEuroCircleFill className={classes.icon} /></Feature>
            </div>
            <div className={classes.featureBox}>
              <Feature title={"Kaderplanung Ersatzspieler"} subtext={"Stammspieler von etwas schwächeren Clubs für die Bank? Kein Problem mit den detaillierten Einstellungen für Ersatzspieler."}><FaExchangeAlt className={classes.icon} /></Feature>
            </div>
            <div className={classes.featureBox}>
              <Feature title={"Spieleranzahl pro Position wählen"} subtext={"Wähle die gewünschte maximale und minimale Anzahl Punktespieler pro Position und definiere damit wieviel Spielraum die KI hat."}><BiSliderAlt className={classes.icon} /></Feature>
            </div>
          </div>

        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.gridItem}>
            <div className={classes.featureBox}>
              <Feature title={"Kader mit Geheimtipps auffüllen"} subtext={"Kickzteam kann deinen Kader mit günstigen Spielern auffüllen, die eine gute Vorbereitung zeigen und Chancen auf Einsätze haben. Dies wird aus dem aktuellen Marktwert der Spieler abgeleitet. Je nach verfügbarem Restbudget können diese auch etwas teurer als 0,5 Mio sein."}><FaUserSecret className={classes.icon} /></Feature>
            </div>
            <div className={classes.featureBox}>
              <Feature title={"Wunschspieler ins Team holen"} subtext={"Du hast schon einen oder mehrere Spieler auf dem Zettel? Wähle sie aus und lasse Kickzteam die Mannschaft um deine ausgewählten Spieler komplettieren."}><BsPersonCheckFill className={classes.icon} /></Feature>
            </div>
            <div className={classes.featureBox}>
              <Feature title={"Spieler ausschließen"} subtext={"Wähle Spieler, die auf keinen Fall in deinerm Team landen düfen."}><BsFillPersonDashFill className={classes.icon} /></Feature>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.gridItem}>
            <Divider className={classes.divider} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.gridItem}>
            {!loginStatus.pro &&
              <div>
                <div className={classes.ticket}>
                  <div >
                    <Typography variant="h6" >
                      Pro-Ticket Sommer 2024
                    </Typography>
                  </div>
                  <Typography variant="h6" >
                    {process.env.REACT_APP_PRO_PRICE_EURO.replace(".", ",")}€
                  </Typography>
                </div>
                <div className={classes.checkListItem}>
                  <Typography variant="body2" >
                    <FaCheckCircle className={classes.checkListIcon} /> einmalige Zahlung
                  </Typography>
                </div>
                <div className={classes.checkListItem}>
                  <Typography variant="body2" >
                    <FaCheckCircle className={classes.checkListIcon} /> kein Abo
                  </Typography>
                </div>
                <div className={classes.checkListItem}>
                  <Typography variant="body2" >
                    <FaCheckCircle className={classes.checkListIcon} /> nutzbar bis 31.10.2024
                  </Typography>
                </div>
                {/*                 <div className={classes.checkListItem}>
                  <Typography variant="body2" >
                    <FaCheckCircle className={classes.checkListIcon} /> Zahlungsmethoden: <FaPaypal />, <FaCreditCard /> (auch ohne PayPal-Account über PayPal-Button möglich)
                  </Typography>
                </div> */}
              </div>
            }
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.gridItem}>
            {loginStatus.pro ?
              <div >
                <Typography variant="h6" >
                  Viel Spaß mit deiner Pro-Version!
                </Typography>
              </div>
              :
              <div className={classes.payment}>
                <Payment loginStatus={loginStatus} setLoginStatus={setLoginStatus} agbCheckStatus={agbCheckStatus} setAgbCheckStatus={setAgbCheckStatus} setGenericAlertStatus={setGenericAlertStatus} />
              </div>
              // <div className={classes.payment}>
              //   <Typography variant="h6" >
              //     Der Kauf der Pro-Version für den Sommer 2022 wird mit dem Start des Kicker-Mangerspiels 2022/23 freigegeben
              //   </Typography>
              // </div>
            }
          </div>
        </Grid>
      </Grid >
    </div >
  )
}

export default UserProfile
