import * as React from 'react'
import pitchImageFile from '../pitch.jpg'
import { makeStyles } from '@material-ui/core/styles'
import PlayerPanel from './PlayerPanel'
import Typography from '@material-ui/core/Typography'
import Tooltipp from '@material-ui/core/Tooltip'
import Hidden from '@material-ui/core/Hidden'
import { ReactComponent as InstructionsD } from '../instructions_desktop.svg'
import { ReactComponent as InstructionsM } from '../instructions_mobile.svg'
import { useTheme } from 'styled-components'
import Box from '@material-ui/core/Box'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const Pitch = ({ team, instructionVisible, playerList, statsOpen, setStatsOpen }) => {


  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const theme = useTheme()
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      position: "relative"
    },
    pitchImgLight: {
      width: "100%",
      opacity: "0.4"
    },
    pitchImg: {
      width: "100%",
      opacity: "1"
    },
    playerContainer: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%"
    },
    instructions: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%"
    },
    teamInfoContainer: {
      position: "absolute",
      top: "0px",
      left: "0px",
      display: "flex",
      height: "5%",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
      alignContent: "flex-start",
      padding: theme.spacing(0.2, 1, 0),
    },
    dataVersionContainer: {
      position: "absolute",
      top: "0px",
      left: "0px",
      display: "flex",
      height: "100%",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-end",
      alignContent: "flex-end",
      padding: theme.spacing(0, 1, 1),
    },
    positionContainer: {
      display: "flex",
      height: "25%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      flexWrap: "wrap",
    },
    playerRowCenter: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    playerRowSpaceBetween: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    panelContainer: {
      width: "22%",
      margin: theme.spacing(0, 1, 1),
    },
    whiteText: {
      color: "#FFFFFF"
    },
    greyText: {
      color: "#CCCCCC"
    },
    transparentText: {
      opacity: "0.7"
    },
    stats: {
      cursor: "pointer",
      color: "#FFFFFF",
      zIndex: 1,
      textDecoration: "underline"
    },

  }))
  const classes = useStyles()

  // calc the position lists
  const forwards = team.players.filter((p) => (p.position === "FORWARD" && !p.substitute))
  const midfielders = team.players.filter((p) => (p.position === "MIDFIELDER" && !p.substitute))
  const defenders = team.players.filter((p) => (p.position === "DEFENDER" && !p.substitute))
  const goalkeepers = team.players.filter((p) => (p.position === "GOALKEEPER" && !p.substitute))
  var midfieldersUpper = []
  var midfieldersLower = []
  var defendersUpper = []
  var defendersLower = []
  const splitLinesLimit = 4

  if (midfielders.length > splitLinesLimit) {
    midfieldersUpper = midfielders.slice(0, 2)
    midfieldersLower = midfielders.slice(2)
  }
  if (defenders.length > splitLinesLimit) {
    defendersUpper = defenders.slice(0, 2)
    defendersLower = defenders.slice(2)
  }


  const handleStatsClose = () => {
    setStatsOpen(false)
  }

  const handleStatsOpen = () => {
    setStatsOpen(true)
  }

  ///////////////////////////////
  // RETURN
  ///////////////////////////////

  return (
    <div className={classes.root}>
      <img src={pitchImageFile} className={instructionVisible ? classes.pitchImgLight : classes.pitchImg} alt="
      Erstelle dein perfektes Team für das Kicker Managerspiel Interactive mit Hilfe künstlicher Intelligenz.
      Du kannst den Mannschafts-Generator dabei nach deinen eigenen Vorstellungen konfigurieren um die ideale Optimierung zu erreichen.
      "></img>
      <Hidden smDown implementation="css">
        {instructionVisible &&
          <InstructionsD fill={theme.palette.text.primary} className={classes.instructions} />
        }


      </Hidden>
      <Hidden mdUp implementation="css">
        {instructionVisible &&
          <InstructionsM fill={theme.palette.text.primary} className={classes.instructions} />
        }
      </Hidden>

      {/* Budget info */}
      {!instructionVisible && (
        <div className={classes.teamInfoContainer}>
          <ClickAwayListener onClickAway={handleStatsClose}>
            <Tooltipp onClose={handleStatsClose} open={statsOpen} arrow disableFocusListener disableHoverListener disableTouchListener title={
              <React.Fragment>
                <div><Typography variant="subtitle1" color="inherit" >Teamstatistik 2023/24</Typography></div>
                <Box mb={0.5} mt={0}></Box>
                <div><Typography variant="subtitle2" color="inherit" className={classes.transparentText}>Nur Startelf:</Typography></div>
                <div><Typography variant="caption" color="inherit" >Punkte gesamt: {team.stats?.lastSeasonPointsS11Sum}</Typography></div>
                <div><Typography variant="caption" color="inherit" >Tore gesamt: {team.stats?.lastSeasonGoalsS11Sum}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Punkte pro Spieler: {team.stats?.lastSeasonPointsS11Avg}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Note: {team.stats?.lastSeasonAverageGradeS11Avg}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Einsätze pro Spieler: {team.stats?.lastSeasonAppearancesS11Avg}</Typography></div>
                <Box mb={1} mt={0}></Box>
                <Typography variant="subtitle2" color="inherit" className={classes.transparentText}>Alle Punktespieler:</Typography>
                <div><Typography variant="caption" color="inherit" >Punkte gesamt: {team.stats?.lastSeasonPointsPsSum}</Typography></div>
                <div><Typography variant="caption" color="inherit" >Tore gesamt: {team.stats?.lastSeasonGoalsPsSum}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Punkte pro Spieler: {team.stats?.lastSeasonPointsPsAvg}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Note: {team.stats?.lastSeasonAverageGradePsAvg}</Typography></div>
                <div><Typography variant="caption" color="inherit" >&#8709;-Einsätze pro Spieler: {team.stats?.lastSeasonAppearancesPsAvg}</Typography></div>
              </React.Fragment>
            }>
              <Typography onClick={handleStatsOpen} variant="subtitle2" align="center" color="textSecondary" classes={{ root: classes.stats }}>
                Teamwert: {Math.round(team.totalMarketValue * 10) / 10} Mio
              </Typography>
            </Tooltipp>
          </ClickAwayListener>
          <Typography variant="subtitle2" align="center" color="textSecondary" classes={{ root: classes.whiteText }}>
            Restbudget: {Math.round((42.5 - team.totalMarketValue) * 10) / 10} Mio
          </Typography>
        </div>
      )}

      {/* Player Version info */}
      {!instructionVisible && (
        <div className={classes.dataVersionContainer}>
          <Typography variant="body2" align="center" color="textSecondary" classes={{ root: classes.greyText }}>
            Letztes Datenupdate:
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary" classes={{ root: classes.greyText }}>
            {playerList[0].date}
          </Typography>
        </div>
      )}

      {/* The player container */}
      <div className={classes.playerContainer}>

        {/* Forwards */}
        <div className={classes.positionContainer}>
          {forwards.map((player) => (
            <div className={classes.panelContainer}>
              <PlayerPanel player={player}></PlayerPanel>
            </div>
          ))}
        </div>

        {/* Midfielders */}
        {midfielders.length > splitLinesLimit ?
          <div className={classes.positionContainer}>
            <div className={classes.playerRowSpaceBetween}>
              {midfieldersUpper.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
            <div className={classes.playerRowCenter}>
              {midfieldersLower.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
          </div>
          :
          <div className={classes.positionContainer}>
            <div className={classes.playerRowCenter}>
              {midfielders.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
          </div>
        }


        {/* Defenders */}
        {defenders.length > splitLinesLimit ?
          <div className={classes.positionContainer}>
            <div className={classes.playerRowSpaceBetween}>
              {defendersUpper.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
            <div className={classes.playerRowCenter}>
              {defendersLower.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
          </div>
          :
          <div className={classes.positionContainer}>
            <div className={classes.playerRowCenter}>
              {defenders.map((player) => (
                <div className={classes.panelContainer}>
                  <PlayerPanel player={player}></PlayerPanel>
                </div>
              ))}
            </div>
          </div>
        }

        <div className={classes.positionContainer}>
          {goalkeepers.map((player) => (
            <div className={classes.panelContainer}>
              <PlayerPanel player={player}></PlayerPanel>
            </div>
          ))}
        </div>



      </div>
    </div>
  )
}

export default Pitch
