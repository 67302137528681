import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DataStatementContent from './DataStatementContent'

function DataStatement({ dataStatementStatus, setDataStatementStatus }) {
  return (
    <div>
      <Dialog open={dataStatementStatus} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Datenschutzerklärung</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DataStatementContent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDataStatementStatus(false) }} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DataStatement