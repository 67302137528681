import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { FaRegMoneyBillAlt } from "react-icons/fa"
import HeaderSeparator from './HeaderSeparator'
import DiscreteSliderMv from './DiscreteSliderMv'

function MarketValueLimits({ settings, setSettings, playerList, loginStatus, expandMenuEffectStatus }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    fullWidth: {
      width: "100%",
    },
    accordion: {
      backgroundColor: "transparent",
    },
    details: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    toolTip: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(0, 0, -1.5),
    },
    iconDown: {
      marginTop: theme.spacing(0.5),
    },
    noSpace: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
    titleBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
    },
    iconBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      height: "100%",
      width: "40px",
    },
    icon: {
      color: theme.palette.primary.main,
      height: "100%",
      width: "27px",
      margin: theme.spacing(0, 1, 0, 0),
    },
    firstSubHeader: {
      margin: theme.spacing(-1, 0, 1),
    },
  }))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={expandMenuEffectStatus.down ?
            { root: classes.noSpace, expandIcon: classes.iconDown } :
            { root: classes.noSpace }}
        >
          <div className={classes.titleBox}>
            <div className={classes.iconBox}>
              <FaRegMoneyBillAlt className={classes.icon} />
            </div>
            <Typography variant="h6" color="textPrimary" >Marktwerte</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.fullWidth}>

            {/* <div className={classes.firstSubHeader}><HeaderSeparator><Typography variant="subtitle2" color="textSecondary" >Marktwerte begrenzen</Typography></HeaderSeparator></div> */}
            <DiscreteSliderMv title="Max. Marktwert Stürmer" settings={settings} setSettings={setSettings} targetValueName="FORWARD" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} tooltipText="Stelle hier den maximalen Kicker-Marktwert für deine Stürmer ein." />
            <DiscreteSliderMv title="Max. Marktwert Mittelfeldspieler" settings={settings} setSettings={setSettings} targetValueName="MIDFIELDER" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} tooltipText="Stelle hier den maximalen Kicker-Marktwert für deine Mittelfeldspieler ein." />
            <DiscreteSliderMv title="Max. Marktwert Abwehrspieler" settings={settings} setSettings={setSettings} targetValueName="DEFENDER" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} tooltipText="Stelle hier den maximalen Kicker-Marktwert für deine Abwehrspieler ein." />
            <DiscreteSliderMv title="Max. Marktwert Torhüter" settings={settings} setSettings={setSettings} targetValueName="GOALKEEPER" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} tooltipText="Stelle hier den maximalen Kicker-Marktwert für deine Torhüter ein." />

            <Box mb={4} mt={0}>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>


    </div >
  )
}

export default MarketValueLimits

