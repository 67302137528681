import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Badge from '@material-ui/core/Badge';
import { Link } from "react-router-dom";
import InfoTooltip from './InfoTooltip';

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider({ title, settings, setSettings, targetValueName, min, max, badge, disabledFlag, tooltipText }) {

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    sliderLabel: {
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(0, 0, -1),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
  });
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    if (settings[[targetValueName]] !== newValue) {
      setSettings({ ...settings, [targetValueName]: newValue, autoSetPlayerCountRanges: false })
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.sliderLabel}>
        <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={!badge}>
          <Typography variant="body2" id="discrete-slider" >{title}</Typography>
        </Badge>

        <InfoTooltip tooltipText={tooltipText} />

      </div>
      <Slider
        value={settings[[targetValueName]]}
        defaultValue={settings[[targetValueName]]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={1}
        marks
        min={min}
        max={max}
        disabled={disabledFlag}
      />
    </div>
  );
}