import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import { BiSquareRounded } from "react-icons/bi";


function Feature({ children, title, subtext }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
    },
    title: {
      width: "100%",
    },
    subtext: {
      width: "100%",
    },
    textBox: {
      margin: theme.spacing(0, 0, 0, 8),

    },
    iconBox: {
      position: "relative",
    },
    primaryIcon: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      top: 0,
      left: 0,
      fontSize: "40px",
      color: theme.palette.primary.main
    },
    // secondaryIcon: {
    //   position: "absolute",
    //   top: "7px",
    //   left: "7px",
    //   fontSize: "50px",
    //   color: theme.palette.primary.main,
    //   opacity: 0.2
    // }
  }));
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <div className={classes.iconBox}>
        {/* <div className={classes.secondaryIcon}><BiSquareRounded /></div> */}
        <div className={classes.primaryIcon}>{children}</div>
      </div>
      <div className={classes.textBox}>
        <Typography className={classes.title} variant="h6" >{title}</Typography>
        <Typography className={classes.subtext} variant="body" color="textSecondary">{subtext}</Typography>
      </div>
    </div>
  )
}

export default Feature
