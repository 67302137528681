import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Impressum from './Impressum';
import DataStatement from './DataStatement'
import { useState } from 'react'

const Footer = () => {

  const useStyles = makeStyles((theme) => ({
    flexbox: {
      margin: theme.spacing(0, 0, 0.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      flexWrap: "wrap"
    },
    divider: {
      width: "100%",
      margin: theme.spacing(1, 0, 1),
    },
    links: {
      textDecoration: "underline",
      color: "inherit",
      cursor: "pointer",
      margin: theme.spacing(0, 1, 0),
    },
    iconLink: {
      textDecoration: "none",
      color: "inherit",
      cursor: "pointer",
    },
    copyright: {
      margin: theme.spacing(0, 1, 0),
    },
  }));
  const classes = useStyles();

  // Impressum
  const [impressumStatus, setImpressumStatus] = useState(false);

  // Data Statement
  const [dataStatementStatus, setDataStatementStatus] = useState(false);

  return (
    <footer >
      <Impressum impressumStatus={impressumStatus} setImpressumStatus={setImpressumStatus}></Impressum>
      <DataStatement dataStatementStatus={dataStatementStatus} setDataStatementStatus={setDataStatementStatus}></DataStatement>
      <Divider className={classes.divider} />
      <div className={classes.flexbox}>
        <div className={classes.flexbox}>
          <div className={classes.links} onClick={() => { setImpressumStatus(true) }}>Impressum</div>
          <div className={classes.copyright}>Copyright &copy; 2021</div>
          <div className={classes.links} onClick={() => { setDataStatementStatus(true) }}>Datenschutz</div>
        </div>
      </div>
      <div className={classes.flexbox}>
        <a className={classes.iconLink} href="https://www.facebook.com/Kickzteam-113004287717149" target="_blank" rel="noopener nofollow noreferrer">
          <FacebookIcon />
        </a>
        <a className={classes.iconLink} href="https://instagram.com/kickzteam" target="_blank" rel="noopener nofollow noreferrer">
          <InstagramIcon />
        </a>
      </div>
    </footer>
  )
}

export default Footer
