import React from 'react'
import OptimizeButton from './OptimizeButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormationSelector from './FormationSelector'
import { makeStyles } from '@material-ui/core/styles'
import { getOptimalPlayerCounts } from '../util'
import InfoTooltip from './InfoTooltip'
import Typography from '@material-ui/core/Typography'


function PitchHeader({ settings, setSettings, setTeam, loadStatus, setLoadStatus, loginStatus, setInstructionVisible, setGenericAlertStatus, increaseOptiCounter }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    pitchHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "100%",
      margin: theme.spacing(0, 0, 1),
    },
    label: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      alignContent: "flex-start",
      width: "100%",
      wrap: "nowrap",
      margin: theme.spacing(0, 0, -0.5),
    },
    caption: {
      flexGrow: 1
    },
    contentBox: {
      width: "33%",
    },
    formationBox: {
      width: "100%",
      padding: theme.spacing(0, 1, 0, 0),
    },
    teamSizeBox: {
      width: "100%",
      padding: theme.spacing(0, 0, 0, 1),
    },
    buttonBox: {
      width: "100%",
      padding: theme.spacing(0, 1, 0),
    },
    select: {
      width: "100%",
      margin: theme.spacing(0, 0, 0),
    },
    tooltip: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      margin: theme.spacing(-0.2, 0, 0, 1),
    }
  }))
  const classes = useStyles()

  const handleFormationChange = (event) => {
    if (settings.autoSetPlayerCountRanges) {
      const optimalPlayerCounts = getOptimalPlayerCounts(settings.desiredFormation, event.target.value)
      setSettings({
        ...settings,
        desiredTeamSize: event.target.value,
        defenderRange: optimalPlayerCounts.defenderRange,
        midfielderRange: optimalPlayerCounts.midfielderRange,
        forwardRange: optimalPlayerCounts.forwardRange,
      })
    } else {
      setSettings({
        ...settings,
        desiredTeamSize: event.target.value
      })
    }
  }



  return (
    <div className={classes.pitchHeader}>
      <div className={classes.contentBox}>
        <div className={classes.formationBox}>
          <FormationSelector settings={settings} setSettings={setSettings} />
        </div>
      </div>
      <div className={classes.contentBox}>
        <div className={classes.buttonBox}>
          <OptimizeButton settings={settings} setTeam={setTeam} loadStatus={loadStatus} setLoadStatus={setLoadStatus} loginStatus={loginStatus} setInstructionVisible={setInstructionVisible} setGenericAlertStatus={setGenericAlertStatus} increaseOptiCounter={increaseOptiCounter} />
        </div>
      </div>
      <div className={classes.contentBox}>
        <div className={classes.teamSizeBox}>

          <div className={classes.label}>
            <Typography className={classes.caption} variant="caption" color="textSecondary" >Punktespieler</Typography>
            <div className={classes.tooltip} ><InfoTooltip
              tooltipText="Punktespieler sind die Spieler für die du wirklich Geld ausgibst und von denen du Einsätze und Punkte erwartest.
               Der Rest deines Teams wird mit Füllspielern (0,5 Mio) aufgefüllt, welche zur Unterscheidung auch grafisch etwas schwächer dargestellt sind." />
            </div>
          </div>

          <Select className={classes.select}

            id="teamsize-select"
            value={settings.desiredTeamSize}
            onChange={handleFormationChange}
          >
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={13}>13</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={17}>17</MenuItem>
          </Select>

        </div>
      </div>
    </div>
  )
}

export default PitchHeader
