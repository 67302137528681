// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useNavigate } from "react-router-dom"
import { launchLogin, performLogout } from '../authScripts'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
//import logoImageFile from '../KT_ball.png'; 
import logoImageFile from '../page_header.svg'

const Header = ({ loginStatus, setLoginStatus, handleDrawerToggle, showMenuIcon }) => {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
      cursor: "pointer",
      height: '40px',
      float: "left"
    },
    avatar: {
      cursor: "pointer"
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md') || !showMenuIcon]: {
        display: 'none',
      },
    },
    hidden: {
      display: 'none',
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    containerLeft: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  }))
  const classes = useStyles()

  ///////////////////////////////
  //states
  ///////////////////////////////

  const [anchorEl, setAnchorEl] = useState(null)

  ///////////////////////////////
  //FUNKTIONS
  ///////////////////////////////

  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  ///////////////////////////////
  // RETURN
  ///////////////////////////////

  return (
    <AppBar position="static">
      <Toolbar>
        <div className={classes.headerContainer}>
          <div className={classes.containerLeft}>
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={[classes.menuButton, !showMenuIcon && classes.hidden].filter(e => e).join(" ")}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div>
              <img src={logoImageFile} className={classes.title} alt="kickzteam" onClick={() => { navigate("/") }}></img>
            </div>
          </div>
          <div>
            {loginStatus.loggedIn ?
              <><Avatar onClick={handleClick} className={classes.avatar}>{loginStatus.email.slice(0, 2)}</Avatar>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={() => { handleClose(); navigate("/profile") }}>Pro-Version</MenuItem>
                  <MenuItem onClick={() => { handleClose(); performLogout(setLoginStatus, loginStatus); navigate("/") }}>Logout</MenuItem>
                </Menu></> :
              <Button color="inherit" onClick={() => { launchLogin(setLoginStatus, loginStatus) }}>Login</Button>}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
