import { useEffect } from 'react'

function Login() {

  useEffect(() => {
    if (window.opener !== null) {
      // parse the cognito redirect and extract token
      const urlString = window.location.href.replace("#", "?")
      const url = new URL(urlString);
      const idToken = new URLSearchParams(url.search).get('id_token')
      const accessToken = new URLSearchParams(url.search).get('access_token')

      //post token to opener window which is the original app
      const targetOrigin = urlString.split('?')[0].replace("/login", "")
      window.opener.postMessage(JSON.stringify({ idToken: idToken, accessToken: accessToken }), targetOrigin);
    }
    window.close()
  }, []);

  return (
    <div>

    </div>
  )
}

export default Login
