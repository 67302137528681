// fetch with retries
export const fetchPlus = (url, options = {}, retries) =>
  fetch(url, options)
    .then(res => {
      const noRetryCodes = [404, 401]
      if (res.ok || noRetryCodes.includes(res.status)) {
        return res
      }
      if (retries > 0) {
        return fetchPlus(url, options, retries - 1)
      }
      return res
    })
    .catch(error => console.error(error.message))

//get Players
export const getPlayers = async () => {
  const res = await fetchPlus(`${process.env.REACT_APP_API_HOST}/sys/players`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    }, 3)
  const data = await res.json()
  const status = res.status

  if (status === 200) {
    return data
  } else {
    return []
  }
}

//get Team
export const fetchTeam = async (teamId) => {
  var data = ""
  var status = 500

  try {
    const res = await fetchPlus(`${process.env.REACT_APP_API_HOST}/exp/teams/${teamId}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }, 3)
    data = await res.json()
    status = res.status
  } catch (e) {
    data = ""
    status = 500
    console.log(e)
  }
  return { status: status, body: data }
}


// post team
export const postTeam = async (settings, loginStatus) => {

  var path = `${process.env.REACT_APP_API_HOST}/exp/teams/optimize`
  var headers = {
    'content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY
  }

  if (loginStatus.pro) {
    path = `${process.env.REACT_APP_API_HOST}/exp/pro/teams/optimize`
    headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${loginStatus.idToken}`,
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  }

  var data = ""
  var status = 500

  try {
    const res = await fetchPlus(path,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
          {
            "desiredTeamSize": settings.desiredTeamSize,
            "minKeepers": settings.keeperRange[0],
            "maxKeepers": settings.keeperRange[1],
            "minDefenders": settings.defenderRange[0],
            "maxDefenders": settings.defenderRange[1],
            "minMidfielders": settings.midfielderRange[0],
            "maxMidfielders": settings.midfielderRange[1],
            "minStrikers": settings.forwardRange[0],
            "maxStrikers": settings.forwardRange[1],
            "desiredFormation": settings.desiredFormation,
            "excludePlayers": settings.excludePlayers,
            "includePlayers": settings.includePlayers,
            "addSubstituteKeepers": settings.addSubstituteKeepers,
            "fillUpSquadWithBestPlayers": settings.fillUpSquadWithBestPlayers,
            "maxMvByPosition": settings.maxMvByPosition,
            "maxPlayersPerClub": settings.maxPlayersPerClub,
            "strengthLowerLimit": 0.1,
            "subStrengthLowerLimit": 0.1,
            "wCurrentMarketValue": settings.wCurrentMarketValue,
            "wLastSeasonPoints": settings.wLastSeasonPoints,
            "wLastSeasonAvgGrade": settings.wLastSeasonAvgGrade,
            "wTeamRating": settings.wTeamRating,
            "wLastSeasonAppear": settings.wLastSeasonAppear,
            "wStartingChance": settings.wStartingChance,
            "wLastSeasonGoals": settings.wLastSeasonGoals,
            "wTeamRatingKeeper": settings.wTeamRatingKeeper,
            "wSubCurrentMarketValue": settings.wSubCurrentMarketValue,
            "wSubLastSeasonPoints": settings.wSubLastSeasonPoints,
            "wSubLastSeasonAvgGrade": settings.wSubLastSeasonAvgGrade,
            "wSubTeamRating": settings.wSubTeamRating,
            "wSubLastSeasonAppear": settings.wSubLastSeasonAppear,
            "wSubStartingChance": settings.wSubStartingChance,
            "wSubLastSeasonGoals": settings.wSubLastSeasonGoals,
            "wSubTeamRatingKeeper": settings.wSubTeamRatingKeeper,
            "workersTotal": 13
          }
        )
      }, 3)

    data = await res.json()
    status = res.status
  } catch (e) {
    data = ""
    status = 500
    console.log(e)
  }

  return { postStatus: status, teamId: data }
}