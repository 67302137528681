import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Agb({ agbStatus, setAgbStatus }) {
  return (
    <div>
      <Dialog
        open={agbStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">AGB</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <h3>Allgemeine Gesch&auml;ftsbedingungen der Quasarfox O&Uuml; (Limited)</h3>
              <br />
              <p>
                <h4>&sect;1 Geltung gegen&uuml;ber Unternehmern und Begriffsdefinitionen</h4>
              </p>
              <p>
                (1) Die nachfolgenden Allgemeinen Gesch&auml;ftbedingungen gelten f&uuml;r alle Dienstleistungen zwischen uns und einem Verbraucher in ihrer zum Zeitpunkt der Bestellung g&uuml;ltigen Fassung.
                <br /><br />
                Verbraucher ist jede nat&uuml;rliche Person, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen (&sect; 13 BGB).
                <br /><br />
              </p>
              <p>
                <h4>&sect;2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes</h4>
              </p>
              <p>
                (1) Die folgenden Regelungen &uuml;ber den Vertragsabschluss gelten f&uuml;r den Erwerb einer Nutzungserlaubnis f&uuml;r die digitalen Services auf www.kickzteam.de
                <br /> <br />
                (2) Im Falle des Vertragsschlusses kommt der Vertrag mit
                <br />

              </p>
              <div>
                <br />
                Quasarfox O&Uuml; (Limited)  <br />
                Sepapaja 6 <br />
                15551 Tallin  <br />
                Estland <br />
                e-Business Register: 16257111 <br /><br />
              </div>
              <p>

                zustande.
                <br /><br />
                (3) Der Verbraucher gibt ein bindendes Vertragsangebot ab, indem er die auf der Webseite vorgesehene Bezahlprozedur erfolgreich durchl&auml;uft.
                <br /><br />
                Durch Annahme der Zahlung (Capture) nehmen wir Ihr Angebot an. Sie erhalten eine Best&auml;tigung von Ihrem Payment Provider (Paypal).
                <br /><br />
                (4) Die AGB k&ouml;nnen Sie jederzeit auch unter https://www.kickzteam.de/ einsehen. Ihre Bestelldaten sind aus Sicherheitsgr&uuml;nden nicht mehr &uuml;ber das Internet zug&auml;nglich.
                <br /> <br />
              </p>
              <p>
                <h4>&sect;3 Preise, Versandkosten, Zahlung, F&auml;lligkeit</h4>
              </p>
              <p>
                (1) Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer.
                <br /> <br />
                (2) Der Verbraucher hat die M&ouml;glichkeit der Zahlung per PayPal oder Kreditkarte (Visa, Mastercard, beides &uuml;ber Paypal) .
                <br /><br /> <br />
              </p>
              <p>
                <h4>&sect;4 Lieferung</h4>
              </p>
              <p>
                (1) Der Zugriff auf die digitalen Services wir in der Regel innerhalb weniger Minuten gew&auml;hrt.
                <br /> <br />

              </p><hr /><br />
              <p>
                <h4>&sect;5 Widerrufsrecht des Kunden als Verbraucher</h4>
              </p>
              <p>
                <strong>
                  Widerrufsrecht f&uuml;r Verbraucher
                  <br />
                </strong>
                <br />
                Verbrauchern steht ein Widerrufsrecht nach folgender Ma&szlig;gabe zu, wobei Verbraucher jede nat&uuml;rliche Person ist, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen:
                <br />
              </p>
              <p>
                <br />
                <strong>
                  Widerrufsrecht
                </strong>
                <br />     <br />
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen. Dies gilt nicht, wenn Sie den zahlungspflichtigen digitalen Service bereits verwendet haben.
                <br />
                Die Widerrufsfrist betr&auml;gt vierzehn Tage, ab dem Tag des Vertragsabschlusses.
                <br />  <br />
                Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns
              </p>
              <div>
                Quasarfox O&Uuml; (Limited) <br />
                Sepapaja 6   <br />
                15551 Tallin  <br />
                Estland  <br />
                e-Business Register: 16257111   <br />
                E-Mail: kickzteam@quasarfox.de
              </div>
              <p>
                mittels einer eindeutigen Erkl&auml;rung per E-Mail &uuml;ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
                <br />    <br />
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                <br /> <br />
                <strong>
                  Widerrufsfolgen
                </strong>
                <br />  <br />
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet.
                <br /> <br /> <br /> <br />
                <strong>
                  Ende der Widerrufsbelehrung
                </strong>
                <br /> <br /> <hr />  <br /> <br />
              </p>
              <p>
                <h4>&sect;6 Gew&auml;hrleistung</h4>
              </p>
              <p>
                Es gelten die gesetzlichen Gew&auml;hrleistungsregelungen.
                <br /> <br />
              </p>
              <p>
                <h4>&sect;7 Vertragssprache</h4>
              </p>
              <p>
                Als Vertragssprache steht ausschlie&szlig;lich Deutsch zur Verf&uuml;gung.
                <br />  <br /> <br />
              </p>
              <p>
                Stand der AGB Jul.2021
              </p>
              <p>
                <em>
                  <a href="http://www.agb.de">
                    Gratis AGB
                  </a>
                  erstellt von agb.de
                </em>
              </p>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAgbStatus(false); }} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default Agb
