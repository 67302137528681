import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import InfoTooltip from './InfoTooltip';

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export default function DiscreteSlider({ title, badge, disabledFlag, settings, setSettings, targetValueName, tooltipText }) {


  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    sliderLabel: {
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(0, 0, -1),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
  });
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    // if (settings[[targetValueName]] !== newValue) {
    //   setSettings({ ...settings, [targetValueName]: newValue })
    // }
    setSettings({ ...settings, [targetValueName]: newValue })
  };

  function valuetext(value) {
    if (value < 0.5) return "Unwichtig";
    if (value >= 0.5 && value < 1.5) return "Etwas wichtig";
    if (value >= 1.5 && value < 2.5) return "Relativ wichtig";
    if (value >= 2.5 && value < 3.5) return "Sehr wichtig";
    if (value >= 3.5) return "Extrem wichtig";
  }

  return (
    <div className={classes.root}>

      <div className={classes.sliderLabel}>
        <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={!badge}>
          <Typography variant="body2" id="continuous-slider" >{title}</Typography>
        </Badge>

        <InfoTooltip tooltipText={tooltipText} />
      </div>

      <Slider
        onChange={handleChange}
        value={settings[[targetValueName]]}
        defaultValue={settings[[targetValueName]]}
        valueLabelFormat={valuetext}
        ValueLabelComponent={ValueLabelComponent}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="auto"
        step={0.01}
        //marks
        min={-0.5}
        max={4.5}
        disabled={disabledFlag}
      />

    </div>
  );
}