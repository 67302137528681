import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

function HeaderSeparator(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    linebox: {
      flexGrow: 1,
    },
    titleBox: {
      margin: theme.spacing(0, 0, 0, 1),
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.linebox}><Divider /></div>
      <div className={classes.titleBox}>{props.children}</div>
    </div>
  )
}

export default HeaderSeparator
