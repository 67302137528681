import DiscreteSlider from './DiscreteSlider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HeaderSeparator from './HeaderSeparator'
import { GiSoccerField } from "react-icons/gi"

function BasicSettings({ settings, setSettings, loginStatus, expandMenuEffectStatus }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      margin: theme.spacing(0, 0, 0),
    },
    titleBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
    },
    iconBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      height: "100%",
      width: "40px",
    },
    icon: {
      color: theme.palette.primary.main,
      height: "100%",
      width: "33px",
      margin: theme.spacing(0, 1, 0, 0),
    },
    accordion: {
      backgroundColor: "transparent",
    },
    details: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    noSpace: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    iconDown: {
      marginTop: theme.spacing(0.5),
    },
    firstSubHeader: {
      margin: theme.spacing(-1, 0, 1),
    },
    middleSubHeader: {
      margin: theme.spacing(1, 0, 1),
    },
  }))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={expandMenuEffectStatus.down ?
            { root: classes.noSpace, expandIcon: classes.iconDown } :
            { root: classes.noSpace }}
          expanded
        >
          <div className={classes.titleBox}>
            <div className={classes.iconBox}>
              <GiSoccerField className={classes.icon} />
            </div>
            <Typography variant="h6" color="textPrimary" >Erste Elf</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.root}>
            <div className={classes.firstSubHeader}><HeaderSeparator><Typography variant="subtitle2" color="textSecondary" >Leistung 2023/24</Typography></HeaderSeparator></div>
            <DiscreteSlider title="Viele Kicker Punkte" settings={settings} setSettings={setSettings} targetValueName="wLastSeasonPoints" tooltipText="Bewege den Slider weiter nach rechts, um dir die Top-Punktesammler der letzten Saison ins Team zu holen. Bedenke aber, dass diese Spieler häufig auch sehr hohe Kicker-Marktwerte haben." />
            <DiscreteSlider title="Gute &#8709;-Note" settings={settings} setSettings={setSettings} targetValueName="wLastSeasonAvgGrade" tooltipText="Bewege den Slider weiter nach rechts, wenn du Wert auf eine gute Durchschnittsnote in der letzten Saison legst. Damit erfasst du auch Topspieler, die letzte Saison zeitweise verletzt waren und nicht so viele Punkte sammeln konnten." />
            <DiscreteSlider title="Viele Einsätze" settings={settings} setSettings={setSettings} targetValueName="wLastSeasonAppear" tooltipText="Bewege den Slider weiter nach rechts, wenn du die Dauerbrenner mit den meisten Einsätzen letzte Saison im Team möchtest." />
            <DiscreteSlider title="Viele Tore" settings={settings} setSettings={setSettings} targetValueName="wLastSeasonGoals" tooltipText="Bewege den Slider weiter nach rechts für die besten Torschützen aus der letzten Saison. So kannst du dir z.B. eher torgefährliche Mittelfeld- oder Abwehrspieler ins Team holen." />
            <div className={classes.middleSubHeader}><HeaderSeparator><Typography variant="subtitle2" color="textSecondary" >Aktuelle Werte</Typography></HeaderSeparator></div>
            <DiscreteSlider title="Feldspieler von starkem Club" settings={settings} setSettings={setSettings} targetValueName="wTeamRating" tooltipText="Bewege den Slider weiter nach rechts für mehr Spieler aus starken Teams. Die Teamwertung wird aus aktuellen Wettquoten abgeleitet." />
            <DiscreteSlider title="Keeper von starkem Club" settings={settings} setSettings={setSettings} targetValueName="wTeamRatingKeeper" tooltipText="Bewege den Slider weiter nach rechts für Torhüter aus starken Teams. Die Teamwertung wird aus aktuellen Wettquoten abgeleitet." />
            <DiscreteSlider title="Stammspieler" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="wStartingChance" tooltipText="Bewege den Slider weiter nach rechts für Spieler, die wahrscheinlich Stammspieler sind. Die Einschätzung stammt von Experten wie Ligainsider und später auch aus der DFB-Pokal Startelf." />
            <DiscreteSlider title="Hoher aktueller Marktwert" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="wCurrentMarketValue" tooltipText="Vielleicht deine stärkste Waffe: Richte dein Team nach dem aktuellen Marktwert aus, welcher die Leistungen in der Vorbereitung und das Know-How der besten deutschen Manager widerspiegelt. Dafür werden die Marktwerte aus verschiedenen Quellen zusammengefasst und ständig aktualisiert (z.B. Comunio, Kickbase, ...)" />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default BasicSettings


