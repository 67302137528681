import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'
import PlayerSelector from './PlayerSelector'
import { Link } from "react-router-dom"
import InfoTooltip from './InfoTooltip'
import { BsPersonBoundingBox } from "react-icons/bs"
import HeaderSeparator from './HeaderSeparator'
import DiscreteSliderMv from './DiscreteSliderMv'

function PlayerSelection({ settings, setSettings, playerList, loginStatus, expandMenuEffectStatus }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    fullWidth: {
      width: "100%",
    },
    accordion: {
      backgroundColor: "transparent",
    },
    details: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    toolTip: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(0, 0, -1.5),
    },
    iconDown: {
      marginTop: theme.spacing(0.5),
    },
    noSpace: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
    titleBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
    },
    iconBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      height: "100%",
      width: "40px",
    },
    icon: {
      color: theme.palette.primary.main,
      height: "100%",
      width: "23px",
      margin: theme.spacing(0, 1, 0, 0),
    },
    firstSubHeader: {
      margin: theme.spacing(-1, 0, 1),
    },
  }))
  const classes = useStyles()

  const handleChangeInclude = (event, newValue) => {
    var playerIdList = []
    newValue.forEach((player) => { playerIdList.push(player.id) })
    setSettings({ ...settings, includePlayers: playerIdList, includePlayersFull: newValue })
  }

  const handleChangeExclude = (event, newValue) => {
    var playerIdList = []
    newValue.forEach((player) => { playerIdList.push(player.id) })
    setSettings({ ...settings, excludePlayers: playerIdList, excludePlayersFull: newValue })
  }

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={expandMenuEffectStatus.down ?
            { root: classes.noSpace, expandIcon: classes.iconDown } :
            { root: classes.noSpace }}
        >
          <div className={classes.titleBox}>
            <div className={classes.iconBox}>
              <BsPersonBoundingBox className={classes.icon} />
            </div>
            <Typography variant="h6" color="textPrimary" >Spielerauswahl</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.fullWidth}>

            <div className={classes.toolTip}>
              <InfoTooltip tooltipText={"Du hast schon einen oder mehrere Spieler auf dem Zettel? Wähle sie hier aus und lasse Kickzteam die Mannschaft um deine ausgewählten Spieler komplettieren."} />
            </div>
            <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={(loginStatus.loggedIn && loginStatus.pro)} classes={{ root: classes.fullWidth }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
              <PlayerSelector value={settings.includePlayersFull} handleChange={handleChangeInclude} title="Wunschspieler" loginStatus={loginStatus} playerList={playerList}></PlayerSelector>
            </Badge>

            <Box mb={2} mt={0}>
            </Box>

            <div className={classes.toolTip}>
              <InfoTooltip tooltipText={"Wähle hier Spieler aus, die auf keinen Fall im Team auftauchen sollen."} />
            </div>
            <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={(loginStatus.loggedIn && loginStatus.pro)} classes={{ root: classes.fullWidth }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} >
              <PlayerSelector value={settings.excludePlayersFull} handleChange={handleChangeExclude} title="Spieler ausschließen" loginStatus={loginStatus} playerList={playerList}></PlayerSelector>
            </Badge>

            <Box mb={4} mt={0}>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>


    </div >
  )
}

export default PlayerSelection

