import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import bayern from '../logos/FC_Bayern_München_logo.svg'
import union from '../logos/union_70th.svg'
import leverkusen from '../logos/Bayer_Leverkusen_Logo.svg'
import dortmund from '../logos/Borussia_Dortmund_logo.svg'
import gladbach from '../logos/Borussia_Mönchengladbach_logo.svg'
import frankfurt from '../logos/Eintracht_Frankfurt_Logo.svg'
import koeln from '../logos/Emblem_1.FC_Köln.svg'
import schalke from '../logos/FC_Schalke_04_Logo.svg'
import hertha from '../logos/Hertha_BSC_Logo_2012.svg'
import leipzig from '../logos/leipzig.svg'
import bielefeld from '../logos/Logo_Arminia_Bielefeld.svg'
import augsburg from '../logos/Logo_FC_Augsburg.svg'
import mainz from '../logos/Logo_Mainz_05.svg'
import hoffenheim from '../logos/Logo_TSG_Hoffenheim.svg'
import wolfsburg from '../logos/Logo-VfL-Wolfsburg.svg'
import freiburg from '../logos/SC-Freiburg_Logo-neu.svg'
import bremen from '../logos/SV-Werder-Bremen-Logo.svg'
import stuttgart from '../logos/VfB_Stuttgart_1893_Logo.svg'
import bochum from '../logos/VfL_Bochum_logo.svg'
import fuerth from '../logos/SpVgg_Greuther_Fürth_2017.svg'
import heidenheim from '../logos/1._FC_Heidenheim_1846.svg'
import darmstadt from '../logos/SV_Darmstadt_98_Logo.svg'
import pauli from '../logos/FC_St._Pauli_logo_2018.svg'
import kiel from '../logos/Holstein_Kiel_Logo.svg'


function PlayerPanel({ player }) {
  var ReactFitText = require('react-fittext')

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    container: {
      position: "relative",
      width: "100%",
      height: "50px",
    },
    backgroundPaper: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "50px",
      padding: theme.spacing(0.5, 0.5, 0.5),
      opacity: 0.6,
    },
    backgroundPaperFillPlayer: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "50px",
      padding: theme.spacing(0.5, 0.5, 0.5),
      opacity: 0.5,
    },
    panelContent: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "50px",
      padding: theme.spacing(0.5, 0.5, 0.5),
      opacity: 1,
    },
    panelContentFillPlayer: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "50px",
      padding: theme.spacing(0.5, 0.5, 0.5),
      opacity: 0.5,
    },
    logoImgBig: {
      height: "40px",
      margin: theme.spacing(0, -2, -2, 0),
    },
    logoImg: {
      height: "35px",
      margin: theme.spacing(0, -2, -2, 0),
    },
    logoImgLeipzig: {
      height: "30px",
      margin: theme.spacing(0, -3, -2, 0),
    },
    nameBox: {
      width: "100%",
      height: "66%",
      margin: theme.spacing(0, 0, 0),
      whiteSpace: "nowrap",
    },
    detailBox: {
      display: "flex",
      flexWrap: "nowrap",
      height: "33%",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mvBox: {
      flexGrow: 1,
      height: "100%",
      whiteSpace: "nowrap",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    logoBox: {
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    playerLink: {
      textDecoration: "none",
      color: "inherit"
    },
  }))
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Paper className={player.fillPlayer ? classes.backgroundPaperFillPlayer : classes.backgroundPaper}></Paper>
      <div className={player.fillPlayer ? classes.panelContentFillPlayer : classes.panelContent}>

        <div className={classes.nameBox}>
          {/* <ReactFitText compressor={player.displayName.length > 0 ? Math.max(player.displayName.length / 18, 0.5) : 0} minFontSize={10} maxFontSize={15}> */}
          <ReactFitText compressor={player.displayName.length > 8 ? Math.max(player.displayName.length / 18, 0.6) : 0} minFontSize={10} maxFontSize={15}>
            <Typography variant="subtitle2" align="center" color={player.fillPlayer ? "textSecondary" : "textPrimary"}>
              <a className={classes.playerLink} href={"https://www.kicker-libero.de/api/sportsdata/v1/player-profile-redirect/" + player.id + "/lg-k0001"} target="_blank" rel="noopener nofollow noreferrer">
                {player.displayName}
              </a>
            </Typography>
          </ReactFitText>
        </div>
        <div className={classes.detailBox}>
          <div className={classes.mvBox}>
            <ReactFitText compressor={0.5} minFontSize={10} maxFontSize={15}>
              <Typography variant="subtitle2" align="center" color={player.fillPlayer ? "textSecondary" : "textPrimary"}>
                {player.marketValue} Mio
              </Typography>
            </ReactFitText>
          </div>
          <div className={classes.logoBox}>
            {player.teamId === 'tm-k00000014' && <img src={bayern} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000002' && <img src={schalke} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000004' && <img src={bremen} className={classes.logoImgBig} alt=""></img>}
            {player.teamId === 'tm-k00000007' && <img src={freiburg} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000009' && <img src={leverkusen} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000010' && <img src={bielefeld} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000011' && <img src={stuttgart} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000015' && <img src={gladbach} className={classes.logoImgBig} alt=""></img>}
            {player.teamId === 'tm-k00000016' && <img src={koeln} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000017' && <img src={dortmund} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000024' && <img src={wolfsburg} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000029' && <img src={hertha} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000030' && <img src={mainz} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000032' && <img src={frankfurt} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000062' && <img src={union} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000091' && <img src={augsburg} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00003209' && <img src={hoffenheim} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00015778' && <img src={leipzig} className={classes.logoImgLeipzig} alt=""></img>}
            {player.teamId === 'tm-k00000008' && <img src={bochum} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000082' && <img src={fuerth} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00011306' && <img src={heidenheim} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000098' && <img src={darmstadt} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00000018' && <img src={pauli} className={classes.logoImg} alt=""></img>}
            {player.teamId === 'tm-k00001297' && <img src={kiel} className={classes.logoImg} alt=""></img>}
          </div>
        </div>
      </div >
    </div>
  )
}

export default PlayerPanel
