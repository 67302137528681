import Typography from '@material-ui/core/Typography'
import RangeSlider from './RangeSlider'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import { getOptimalPlayerCounts } from '../util'
import { Link } from "react-router-dom"
import InfoTooltip from './InfoTooltip'
import { useTheme } from 'styled-components'
import { ReactComponent as Strategy } from '../noun_strategy_332871.svg'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

function TeamStructure({ settings, setSettings, loginStatus, expandMenuEffectStatus }) {

  const theme = useTheme()
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      margin: theme.spacing(0, 0, 0),
    },
    accordion: {
      backgroundColor: "transparent",
    },
    details: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    noSpace: {
      padding: theme.spacing(0, 0, 0),
      margin: theme.spacing(0, 0, 0),
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    iconDown: {
      marginTop: theme.spacing(0.5),
    },
    checkBoxLable: {
      margin: theme.spacing(0, 0, 0, 1),
    },
    checkBoxContainer: {
      display: "flex",
      width: "100%",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      margin: theme.spacing(1, 0, 0, 0),
    },
    select: {
      display: "flex",
      width: "30px",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      margin: theme.spacing(1, 1, 0, 0),
      alignItems: "flex-start",
      left: theme.spacing(1),
      top: theme.spacing(-1.5),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
    badgeSelect: {
      left: theme.spacing(-1),
      top: theme.spacing(1),
    },
    titleBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
    },
    iconBox: {
      margin: theme.spacing(0, 0, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      height: "100%",
      width: "40px",
    },
    icon: {
      color: theme.palette.primary.main,
      height: "100%",
      width: "30px",
      margin: theme.spacing(0, 1, 0, 0),
    },
  }))
  const classes = useStyles()


  const handleOptimalPlayerCountSwitch = (event, newValue) => {
    if (newValue) {
      const optimalPlayerCounts = getOptimalPlayerCounts(settings.desiredFormation, settings.desiredTeamSize)
      setSettings({
        ...settings,
        defenderRange: optimalPlayerCounts.defenderRange,
        midfielderRange: optimalPlayerCounts.midfielderRange,
        forwardRange: optimalPlayerCounts.forwardRange,
        autoSetPlayerCountRanges: newValue
      })
    }
    else setSettings({ ...settings, autoSetPlayerCountRanges: newValue })
  }

  const handleAddSubstituteKeepersSwitch = (event, newValue) => {
    // if (newValue & settings.maxPlayersPerClub < 3) {
    //   setSettings({ ...settings, addSubstituteKeepers: newValue, maxPlayersPerClub: 3 })
    // } else
    setSettings({ ...settings, addSubstituteKeepers: newValue })
  }

  const handleMaxPlayersPerClubChange = (event) => {
    // if (event.target.value < 3 && settings.addSubstituteKeepers) {
    //   setSettings({ ...settings, maxPlayersPerClub: event.target.value, addSubstituteKeepers: false })
    // } else 
    setSettings({ ...settings, maxPlayersPerClub: event.target.value })
  }

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={expandMenuEffectStatus.down ?
            { root: classes.noSpace, expandIcon: classes.iconDown } :
            { root: classes.noSpace }}
        >
          <div className={classes.titleBox}>
            <div className={classes.iconBox}>
              <Strategy className={classes.icon} fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            </div>
            <Typography variant="h6" color="textPrimary" >Zusatzoptionen</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.root}>

            <RangeSlider title="Anzahl Stürmer:" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="forwardRange" min={1} max={5} tooltipText="Wähle hier die minimale und maximale Anzahl der Punktespieler im Sturm und entscheide so, welchen Spielraum die KI hat." />
            <RangeSlider title="Anzahl Mittelfeldspieler:" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="midfielderRange" min={3} max={7} tooltipText="Wähle hier die minimale und maximale Anzahl der Punktespieler im Mittelfeld und entscheide so, welchen Spielraum die KI hat." />
            <RangeSlider title="Anzahl Abwehrspieler:" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="defenderRange" min={3} max={7} tooltipText="Wähle hier die minimale und maximale Anzahl der Punktespieler in der Abwehr und entscheide so, welchen Spielraum die KI hat." />
            <RangeSlider title="Anzahl Torhüter:" badge={!(loginStatus.loggedIn && loginStatus.pro)} disabledFlag={!(loginStatus.loggedIn && loginStatus.pro)} settings={settings} setSettings={setSettings} targetValueName="keeperRange" min={1} max={3} tooltipText="Wähle hier die minimale und maximale Anzahl der Punktespieler im Tor und entscheide so, welchen Spielraum die KI hat. Ist die Option 'Ersatzkeeper vom gleichen Club' gewählt, bekommst du immer 3 Keeper." />


            <div className={classes.checkBoxContainer} >
              <FormControlLabel
                control={
                  <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={loginStatus.loggedIn && loginStatus.pro} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="circle" >
                    <Switch
                      checked={settings.autoSetPlayerCountRanges}
                      size="small"
                      color="primary"
                      onChange={handleOptimalPlayerCountSwitch}
                      disabled={!(loginStatus.loggedIn && loginStatus.pro)}
                    />
                  </Badge>
                }
                label={<div className={classes.checkBoxLable} ><Typography variant="body2" id="discrete-slider" className={classes.checkBoxLableText} >Aufstellung optimal füllen</Typography></div>}
              />
              <InfoTooltip tooltipText={"Kickzteam wählt automatisch die sinnvolle Spielerzahl pro Position für dich."} />
            </div>

            <div className={classes.checkBoxContainer} >
              <FormControlLabel
                control={
                  <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={loginStatus.loggedIn && loginStatus.pro} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="circle" >
                    <Switch
                      checked={settings.fillUpSquadWithBestPlayers}
                      size="small"
                      color="primary"
                      disabled={!(loginStatus.loggedIn && loginStatus.pro)}
                      onChange={(event, newValue) => setSettings({ ...settings, fillUpSquadWithBestPlayers: newValue })}
                    />
                  </Badge>
                }
                label={<Typography className={classes.checkBoxLable} variant="body2" id="discrete-slider" >Kader mit Geheimtipps auffüllen</Typography>}
              />
              <InfoTooltip tooltipText={"Kickzteam füllt deinen Kader mit günstigen Spielern auf, die eine gute Vorbereitung zeigen und Chancen auf Einsätze haben. Dies wird aus dem aktuellen Marktwert der Spieler abgeleitet. Je nach verfügbarem Restbudget können diese auch etwas teurer als 0,5 Mio sein."} />
            </div>

            <div className={classes.checkBoxContainer} >
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.addSubstituteKeepers}
                    size="small"
                    color="primary"
                    onChange={handleAddSubstituteKeepersSwitch}
                  />
                }
                label={<Typography className={classes.checkBoxLable} variant="body2">Ersatzkeeper vom gleichen Club</Typography>}
              />
              <InfoTooltip tooltipText={"Die KI gibt dir zu deinem Stammtorwart automatisch die Ersatzkeeper aus dem gleichen Verein."} />
            </div>

            <div className={classes.checkBoxContainer}>
              <Badge className={classes.badgeSelect} badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={loginStatus.loggedIn && loginStatus.pro} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="circle" >
                <Select className={classes.select}
                  disabled={!(loginStatus.loggedIn && loginStatus.pro)}
                  id="teamsize-select"
                  value={settings.maxPlayersPerClub}
                  onChange={handleMaxPlayersPerClubChange}
                  size="small"
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={22}>-</MenuItem>
                </Select>
              </Badge>
              <div className={classes.checkBoxContainer}>
                <Typography className={classes.caption} variant="body2"  >Maximale Anzahl Spieler von einem Club</Typography>
                <div className={classes.tooltip} ><InfoTooltip
                  tooltipText="Du willst dein Risiko verteilen? Schränke hier die maximale Anzahl der Spieler von einem Club ein." />
                </div>
              </div>

            </div>

            <Box mb={2} mt={0}>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>


    </div>
  )
}

export default TeamStructure
