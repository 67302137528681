import React from 'react'

function DataStatementContent({ dataStatementStatus, setDataStatementStatus }) {
  return (
    <div>
      <>
        <h1>Datenschutzerklärung</h1>
        <h2 id="m716">Präambel</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
          welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
          "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
          verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
          Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung
          unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
          Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
          Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
          "Onlineangebot“).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 19. Juli 2023</p>
        <h2>Inhaltsübersicht</h2>{" "}
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Präambel
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#m11">
              Kontakt Datenschutzbeauftragter
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Übermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              Internationale Datentransfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Löschung von Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m134">
              Einsatz von Cookies
            </a>
          </li>
          <li>
            <a className="index-link" href="#m317">
              Geschäftliche Leistungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m326">
              Zahlungsverfahren
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Bereitstellung des Onlineangebotes und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m367">
              Registrierung, Anmeldung und Nutzerkonto
            </a>
          </li>
          <li>
            <a className="index-link" href="#m451">
              Single-Sign-On-Anmeldung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m17">
              Newsletter und elektronische Benachrichtigungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m638">
              Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
            </a>
          </li>
          <li>
            <a className="index-link" href="#m263">
              Webanalyse, Monitoring und Optimierung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m264">
              Onlinemarketing
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Präsenzen in sozialen Netzwerken (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m328">
              Plugins und eingebettete Funktionen sowie Inhalte
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Änderung und Aktualisierung der Datenschutzerklärung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          Quasarfox OÜ (Limited)
          <br />
          Sepapaja 6<br />
          15551 Tallin
          <br />
          Estland
          <br />
          e-Business Register: 16257111
        </p>
        Vertretungsberechtigte Personen: <p>Dr. Kai Schatto</p>
        E-Mail-Adresse:{" "}
        <p>
          <a href="mailto:kickzteam@quasarfox.de">kickzteam@quasarfox.de</a>
        </p>
        Telefon: <p>+372 627 4077</p>
        Impressum:{" "}
        <p>
          <a href="https://kickzteam.de/" target="_blank" rel="noreferrer">
            https://kickzteam.de/
          </a>
        </p>
        <h2 id="m11">Kontakt Datenschutzbeauftragter</h2>
        <p>
          <a href="mailto:kickzteam@quasarfox.de">kickzteam@quasarfox.de</a>
        </p>
        <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
        <p>
          <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden
          erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
          wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass
          neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
          unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
          speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
          Datenschutzerklärung mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
            betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
            betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
            mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
            Person erfolgen.
          </li>
          <li>
            <strong>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der der Verantwortliche unterliegt.
          </li>
          <li>
            <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong>{" "}
            - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
            die den Schutz personenbezogener Daten erfordern, überwiegen.
          </li>
        </ul>
        <p>
          <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
          Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
          schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch
          nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie
          zu beachten, dass aufgrund der breiteren räumlichen Anwendung und
          Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt
          der im Schweizer DSG verwendeten Begriffe „Bearbeitung“ von „Personendaten“
          und "überwiegendes Interesse" werden die in der DSGVO verwendeten Begriffe
          „Verarbeitung“ von „personenbezogenen Daten“ sowie "berechtigtes Interesse"
          verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der
          Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
        </p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
          Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
          Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten.</li>
          <li>Zahlungsdaten.</li>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Vertragsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
          <li>Event-Daten (Facebook).</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kunden.</li>
          <li>Interessenten.</li>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
          <li>Geschäfts- und Vertragspartner.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Direktmarketing.</li>
          <li>Reichweitenmessung.</li>
          <li>Tracking.</li>
          <li>Büro- und Organisationsverfahren.</li>
          <li>Konversionsmessung.</li>
          <li>Verwaltung und Beantwortung von Anfragen.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Profile mit nutzerbezogenen Informationen.</li>
          <li>Anmeldeverfahren.</li>
          <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
          <li>Informationstechnische Infrastruktur.</li>
        </ul>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
          des Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
          der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
          Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
          Freiheiten natürlicher Personen geeignete technische und organisatorische
          Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
          Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
          elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
          der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
          Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
          Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
          auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
          Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
          Hardware, Software sowie Verfahren entsprechend dem Prinzip des
          Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen.
        </p>
        <p>
          TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
          übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie
          erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
          Adresszeile Ihres Browsers.
        </p>
        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
          dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
          Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
          offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
          IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
          Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
          Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
          entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
          dienen, mit den Empfängern Ihrer Daten ab.
        </p>
        <p>
          Datenübermittlung innerhalb der Organisation: Wir können personenbezogene
          Daten an andere Stellen innerhalb unserer Organisation übermitteln oder
          ihnen den Zugriff auf diese Daten gewähren. Sofern diese Weitergabe zu
          administrativen Zwecken erfolgt, beruht die Weitergabe der Daten auf unseren
          berechtigten unternehmerischen und betriebswirtschaftlichen Interessen oder
          erfolgt, sofern sie Erfüllung unserer vertragsbezogenen Verpflichtungen
          erforderlich ist oder wenn eine Einwilligung der Betroffenen oder eine
          gesetzliche Erlaubnis vorliegt.
        </p>
        <h2 id="m24">Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
          (d.h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
          Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung
          von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt
          dies nur im Einklang mit den gesetzlichen Vorgaben.
        </p>
        <p>
          Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
          erforderlicher Übermittlung (s. Art. 49 DSGVO) verarbeiten oder lassen wir
          die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau (Art.
          45 DSGVO), beim Vorliegen und Einhaltung vertraglichen Verpflichtung durch
          sogenannte Standardschutzklauseln der EU-Kommission (Art. 46 DSGVO) oder
          beim Vorliegen von Zertifizierungen oder verbindlicher internen
          Datenschutzvorschriften (s. Art. 44 bis 49 DSGVO, Informationsseite der
          EU-Kommission:{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
            target="_blank" rel="noreferrer"
          >
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
          </a>
          ).
        </p>
        <p>
          Trans-Atlantic Data Privacy Framework (TADPF): Im Rahmen des sogenannten
          „Data Privacy Framework” (DPF) wurde in der EU das Datenschutzniveau
          ebenfalls für bestimmte Unternehmen aus den USA anerkannt. Die Liste der
          zertifizierten Unternehmen als auch weitere Informationen zu dem DPF können
          Sie der Webseite des Handelsministeriums der USA unter{" "}
          <a href="https://www.dataprivacyframework.gov/" target="_blank" rel="noreferrer">
            https://www.dataprivacyframework.gov/
          </a>{" "}
          (in Englisch) entnehmen. Informationen in deutscher und in anderen Sprachen
          finden Sie auf der Webseite der EU-Kommission:{" "}
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/eu-us-data-transfers_de"
            target="_blank" rel="noreferrer"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/eu-us-data-transfers_de
          </a>{" "}
          Wir informieren Sie ferner über die von uns eingesetzten Unternehmen, die
          unter dem Data Privacy Framework zertifiziert sind.
        </p>
        <h2 id="m12">Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
          widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck
          der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
          erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für
          andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
          Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt
          und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
          Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
          juristischen Person erforderlich ist.{" "}
        </p>
        <p>
          Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung
          und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen
          vorrangig gelten.
        </p>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene
          nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis
          21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
              besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
              lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für
              ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
              betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
              derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
              es mit solcher Direktwerbung in Verbindung steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
            erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
            darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
            Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
            Daten entsprechend den gesetzlichen Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen
            Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>{" "}
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
            dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
            nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
            der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
            betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
            gesetzlichen Vorgaben in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
            anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet
            eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
            Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
            ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
            der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>
          Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
          Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
          auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
          Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
          Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
          unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
          Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der
          Erstellung von Analysen der Besucherströme.{" "}
        </p>
        <p>
          <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
          mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
          vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert
          ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern
          und das Auslesen der Informationen, also auch von Cookies, unbedingt
          erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
          gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu
          stellen. Zu den unbedingt erforderlichen Cookies gehören in der Regel
          Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des
          Onlineangebotes , dem Lastausgleich, der Sicherheit, der Speicherung der
          Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
          Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
          angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
          widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
          und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
        </p>
        <p>
          <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
          welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
          Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
          Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
          Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
          Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
          Grundlage unserer berechtigten Interessen (z.B. an einem
          betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
          seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
          unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
          erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
          welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
          im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
          Einwilligungs- und Verarbeitungsprozessen auf.
        </p>
        <p>
          <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
          werden die folgenden Arten von Cookies unterschieden:
        </p>
        <ul>
          <li>
            <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>
            &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
            Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
            Applikation) geschlossen hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach
            dem Schließen des Endgerätes gespeichert. So können beispielsweise der
            Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
            wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe
            von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
            werden. Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
            Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der
            Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind
            und die Speicherdauer bis zu zwei Jahre betragen kann.
          </li>
        </ul>
        <p>
          <strong>
            Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):{" "}
          </strong>
          Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
          und der Verarbeitung entsprechend den gesetzlichen Vorgaben widersprechen.
          Hierzu können Nutzer unter anderem die Verwendung von Cookies in den
          Einstellungen ihres Browsers einschränken (wobei dadurch auch die
          Funktionalität unseres Onlineangebotes eingeschränkt sein kann). Ein
          Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
          kann auch über die Websites{" "}
          <a href="https://optout.aboutads.info/" target="_new">
            https://optout.aboutads.info
          </a>{" "}
          und{" "}
          <a href="https://www.youronlinechoices.com/" target="_new">
            https://www.youronlinechoices.com/
          </a>{" "}
          erklärt werden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO); Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
            </strong>
            Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
            dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
            bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
            genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
            verwaltet und widerrufen werden können. Hierbei wird die
            Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
            wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
            Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig
            und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
            vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
            bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben
            zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
            Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei
            Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator
            gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
            der Einwilligung (z. B. welche Kategorien von Cookies und/oder
            Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät
            gespeichert; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs.
            1 S. 1 lit. a) DSGVO).
          </li>
        </ul>
        <h2 id="m317">Geschäftliche Leistungen</h2>
        <p>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden
          und Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im
          Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit
          verbundenen Maßnahmen und im Rahmen der Kommunikation mit den
          Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.
        </p>
        <p>
          Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu
          erfüllen. Dazu gehören insbesondere die Verpflichtungen zur Erbringung der
          vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei
          Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus
          verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit
          diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
          Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf
          Grundlage unserer berechtigten Interessen an einer ordnungsgemäßen und
          betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum
          Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor
          Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte
          (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen
          Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
          Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts
          geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als
          dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten
          erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu
          Marketingzwecken, werden die Vertragspartner im Rahmen dieser
          Datenschutzerklärung informiert.
        </p>
        <p>
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
          Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
          Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
          (z.B. Sternchen o.ä.), oder persönlich mit.
        </p>
        <p>
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
          vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es
          sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B.,
          solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden
          müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich
          relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
          Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen
          erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und
          Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und
          Geschäftsbriefen und Wiedergaben der abgesandten Handels- und
          Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des
          Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das
          Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
          aufgestellt, der Handels- oder Geschäftsbrief empfangen oder abgesandt
          worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung
          vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.
        </p>
        <p>
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen
          einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
          Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter
          oder Plattformen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
            Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie);
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
            IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Interessenten; Geschäfts-
            und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice; Sicherheitsmaßnahmen; Kontaktanfragen und
            Kommunikation; Büro- und Organisationsverfahren; Verwaltung und
            Beantwortung von Anfragen.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung
            (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Kundenkonto: </strong>Kunden können innerhalb unseres
            Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
            "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich
            ist, werden Kunden hierauf ebenso hingewiesen wie auf die für die
            Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht
            öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen
            der Registrierung sowie anschließender Anmeldungen und Nutzungen des
            Kundenkontos speichern wir die IP-Adressen der Kunden nebst den
            Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
            Missbrauch des Kundenkontos vorbeugen zu können. Wurde das Kundenkonto
            gekündigt, werden die Daten des Kundenkontos nach dem Kündigungszeitpunkt
            gelöscht, sofern sie nicht für andere Zwecke als die Bereitstellung im
            Kundenkonto aufbewahrt werden oder aus rechtlichen Gründen aufbewahrt
            werden müssen (z.B. interne Speicherung von Kundendaten, Bestellvorgängen
            oder Rechnungen). Es liegt in der Verantwortung der Kunden, ihre Daten bei
            Kündigung des Kundenkontos zu sichern; <strong>Rechtsgrundlagen:</strong>{" "}
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
            DSGVO).
          </li>
          <li>
            <strong>Shop und E-Commerce: </strong>Wir verarbeiten die Daten unserer
            Kunden, um ihnen die Auswahl, den Erwerb, bzw. die Bestellung der
            gewählten Produkte, Waren sowie verbundener Leistungen, als auch deren
            Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen. Sofern für die
            Ausführung einer Bestellung erforderlich, setzen wir Dienstleister,
            insbesondere Post-, Speditions- und Versandunternehmen ein, um die
            Lieferung, bzw. Ausführung gegenüber unseren Kunden durchzuführen. Für die
            Abwicklung der Zahlungsvorgänge nehmen wir die Dienste von Banken und
            Zahlungsdienstleistern in Anspruch. Die erforderlichen Angaben sind als
            solche im Rahmen des Bestell- bzw. vergleichbaren Erwerbsvorgangs
            gekennzeichnet und umfassen die zur Auslieferung, bzw.
            Zurverfügungstellung und Abrechnung benötigten Angaben sowie
            Kontaktinformationen, um etwaige Rücksprache halten zu können;{" "}
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
          <li>
            <strong>Angebot von Software- und Plattformleistungen: </strong>Wir
            verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
            (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber
            unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage
            berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten
            und es weiterentwickeln zu können. Die erforderlichen Angaben sind als
            solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
            Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
            und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige
            Rücksprachen halten zu können; <strong>Rechtsgrundlagen:</strong>{" "}
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
            DSGVO).
          </li>
        </ul>
        <h2 id="m326">Zahlungsverfahren</h2>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
          gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
          Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten
          weitere Dienstleister ein (zusammenfassend "Zahlungsdienstleister").
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
          Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
          Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie
          die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
          erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten
          werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
          gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen
          Informationen, sondern lediglich Informationen mit Bestätigung oder
          Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens
          der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
          Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen
          wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.
        </p>
        <p>
          Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
          Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb
          der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir
          verweisen auf diese ebenfalls zwecks weiterer Informationen und
          Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
            Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
            Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z.
            B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Interessenten.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>PayPal: </strong>Zahlungsdienstleistungen (technische Anbindung
            von Online-Bezahlmethoden) (z.B. PayPal, PayPal Plus, Braintree);{" "}
            <strong>Dienstanbieter:</strong> PayPal (Europe) S.à r.l. et Cie, S.C.A.,
            22-24 Boulevard Royal, L-2449 Luxembourg;{" "}
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://www.paypal.com/de" target="_blank" rel="noreferrer">
              https://www.paypal.com/de
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
              target="_blank" rel="noreferrer"
            >
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </a>
            .
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
          Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse
          des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
          Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
            technischen Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
            </strong>
            Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
            Rechenkapazität und Software, die wir von einem entsprechenden
            Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig
            beziehen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
            6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf
            unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
            protokolliert. Zu den Serverlogfiles können die Adresse und Name der
            abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
            übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
            besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
            gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
            eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
            Stabilität sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
            <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die
            Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
            anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
            erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls
            von der Löschung ausgenommen.
          </li>
          <li>
            <strong>Content-Delivery-Network: </strong>Wir setzen ein
            "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit dessen
            Hilfe Inhalte eines Onlineangebotes, insbesondere große Mediendateien, wie
            Grafiken oder Programm-Skripte, mit Hilfe regional verteilter und über das
            Internet verbundener Server schneller und sicherer ausgeliefert werden
            können; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
          <li>
            <strong>Amazon Web Services: </strong>
            Quasarfox OÜ nutzt für das Hosting der Datenbank- und Webinhalte sowie Nutzerdaten den
            Dienst Amazon Web Services („AWS“) der Amazon Web Services, Inc., P.O. Box
            81226, Seattle, WA 98108-1226, USA. Die Daten werden ausschließlich in einem
            deutschen Rechenzentrum (Frankfurt/Main) gespeichert, das nach ISO 27001,
            27017 und 2018 sowie PCI DSS Level 1 zertifiziert ist. Selbstverständlich
            bestehen unsererseits eng begrenzte Zugriffsrechte und die Daten werden
            automatisch verschlüsselt. AWS hat sich als Unternehmen dem sog. Privacy
            Shield-Abkommen angeschlossen. Nähere Informationen zu AWS und zum Datenschutz
            finden Sie
            unter https://aws.amazon.com/de/compliance/eu-data-protection/ sowie
            unter https://aws.amazon.com/de/privacy/.


          </li>
        </ul>
        <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
        <p>
          Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden
          den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der
          Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
          verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die
          Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen
          sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den
          Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf
          Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem
          Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe
          dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur
          Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche
          Verpflichtung hierzu.
        </p>
        <p>
          Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind,
          wie z.B. technische Änderungen, per E-Mail informiert werden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
            Eingaben in Onlineformularen); Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
            Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice; Sicherheitsmaßnahmen; Verwaltung und
            Beantwortung von Anfragen; Bereitstellung unseres Onlineangebotes und
            Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Berechtigte Interessen (Art.
            6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Profile der Nutzer sind nicht öffentlich: </strong>Die Profile der
            Nutzer sind öffentlich nicht sichtbar und nicht zugänglich.
          </li>
          <li>
            <strong>Löschung von Daten nach Kündigung: </strong>Wenn Nutzer ihr
            Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das
            Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht oder
            Einwilligung der Nutzer, gelöscht; <strong>Rechtsgrundlagen:</strong>{" "}
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
            DSGVO).
          </li>
        </ul>
        <h2 id="m451">Single-Sign-On-Anmeldung</h2>
        <p>
          Als "Single-Sign-On“ oder "Single-Sign-On-Anmeldung bzw.
          "-Authentifizierung“ werden Verfahren bezeichnet, die es Nutzern erlauben,
          sich mit Hilfe eines Nutzerkontos bei einem Anbieter von
          Single-Sign-On-Verfahren (z.B. einem sozialen Netzwerk), auch bei unserem
          Onlineangebot, anzumelden. Voraussetzung der
          Single-Sign-On-Authentifizierung ist, dass die Nutzer bei dem jeweiligen
          Single-Sign-On-Anbieter registriert sind und die erforderlichen Zugangsdaten
          in dem dafür vorgesehenen Onlineformular eingeben, bzw. schon bei dem
          Single-Sign-On-Anbieter angemeldet sind und die Single-Sign-On-Anmeldung via
          Schaltfläche bestätigen.
        </p>
        <p>
          Die Authentifizierung erfolgt direkt bei dem jeweiligen
          Single-Sign-On-Anbieter. Im Rahmen einer solchen Authentifizierung erhalten
          wir eine Nutzer-ID mit der Information, dass der Nutzer unter dieser
          Nutzer-ID beim jeweiligen Single-Sign-On-Anbieter eingeloggt ist und eine
          für uns für andere Zwecke nicht weiter nutzbare ID (sog "User Handle“). Ob
          uns zusätzliche Daten übermittelt werden, hängt allein von dem genutzten
          Single-Sign-On-Verfahren ab, von den gewählten Datenfreigaben im Rahmen der
          Authentifizierung und zudem davon, welche Daten Nutzer in den Privatsphäre-
          oder sonstigen Einstellungen des Nutzerkontos beim Single-Sign-On-Anbieter
          freigegeben haben. Es können je nach Single-Sign-On-Anbieter und der Wahl
          der Nutzer verschiedene Daten sein, in der Regel sind es die E-Mail-Adresse
          und der Benutzername. Das im Rahmen des Single-Sign-On-Verfahrens
          eingegebene Passwort bei dem Single-Sign-On-Anbieter ist für uns weder
          einsehbar, noch wird es von uns gespeichert.{" "}
        </p>
        <p>
          Die Nutzer werden gebeten, zu beachten, dass deren bei uns gespeicherte
          Angaben automatisch mit ihrem Nutzerkonto beim Single-Sign-On-Anbieter
          abgeglichen werden können, dies jedoch nicht immer möglich ist oder
          tatsächlich erfolgt. Ändern sich z.B. die E-Mail-Adressen der Nutzer, müssen
          sie diese manuell in ihrem Nutzerkonto bei uns ändern.
        </p>
        <p>
          Die Single-Sign-On-Anmeldung können wir, sofern mit den Nutzern vereinbart,
          im Rahmen der oder vor der Vertragserfüllung einsetzen, soweit die Nutzer
          darum gebeten wurden, im Rahmen einer Einwilligung verarbeiten und setzen
          sie ansonsten auf Grundlage der berechtigten Interessen unsererseits und der
          Interessen der Nutzer an einem effektiven und sicheren Anmeldesystem ein.
        </p>
        <p>
          Sollten Nutzer sich einmal entscheiden, die Verknüpfung ihres Nutzerkontos
          beim Single-Sign-On-Anbieter nicht mehr für das Single-Sign-On-Verfahren
          nutzen zu wollen, müssen sie diese Verbindung innerhalb ihres Nutzerkontos
          beim Single-Sign-On-Anbieter aufheben. Möchten Nutzer deren Daten bei uns
          löschen, müssen sie ihre Registrierung bei uns kündigen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
            Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus); Event-Daten (Facebook)
            ("Event-Daten" sind Daten, die z. B. via Facebook-Pixel (via Apps oder auf
            anderen Wegen) von uns an Facebook übermittelt werden können und sich auf
            Personen oder deren Handlungen beziehen; Zu den Daten gehören z. B.
            Angaben über Besuche auf Websites, Interaktionen mit Inhalten, Funktionen,
            Installationen von Apps, Käufe von Produkten, etc.; die Event-Daten werden
            zwecks Bildung von Zielgruppen für Inhalte und Werbeinformationen (Custom
            Audiences) verarbeitet; Event Daten beinhalten nicht die eigentlichen
            Inhalte (wie z. B. verfasste Kommentare), keine Login-Informationen und
            keine Kontaktinformationen (also keine Namen, E-Mail-Adressen und
            Telefonnummern). Event Daten werden durch Facebook nach maximal zwei
            Jahren gelöscht, die aus ihnen gebildeten Zielgruppen mit der Löschung
            unseres Facebook-Kontos).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Kundenservice; Sicherheitsmaßnahmen; Anmeldeverfahren.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Facebook Single-Sign-On: </strong>Authentifizierungsdienst der
            Plattform Facebook; <strong>Dienstanbieter:</strong> Meta Platforms Irland
            Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              https://www.facebook.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
              https://www.facebook.com/about/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
            <a
              href="https://www.facebook.com/legal/terms/dataprocessing"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/terms/dataprocessing
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000GnywAAC&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000GnywAAC&amp;status=Active
            </a>
            ; <strong>Standardvertragsklauseln:</strong>{" "}
            <a
              href="https://www.facebook.com/legal/EU_data_transfer_addendum"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>
            .
          </li>
        </ul>
        <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
        <p>
          Wir versenden Newsletter, E-Mails und weitere elektronische
          Benachrichtigungen (nachfolgend "Newsletter“) nur mit der Einwilligung der
          Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer
          Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie
          für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere
          Newsletter Informationen zu unseren Leistungen und uns.
        </p>
        <p>
          Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn
          Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen,
          zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern
          diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
        </p>
        <p>
          <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem
          Newsletter erfolgt grundsätzlich in einem sogenannte
          Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail,
          in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese
          Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen
          anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
          Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu
          können. Hierzu gehört die Speicherung des Anmelde- und des
          Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen
          Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.
        </p>
        <p>
          <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können
          die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
          berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
          gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
          wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
          individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
          ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten
          zur dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung
          der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
          "Blocklist") vor.
        </p>
        <p>
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer
          berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen
          Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
          beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an
          einem effizienten und sicheren Versandsystem.
        </p>
        <strong>Inhalte:</strong>{" "}
        <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Meta-,
            Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus); Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail
            oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO).
          </li>
          <li>
            <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang
            unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen
            widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur
            Kündigung des Newsletters finden Sie entweder am Ende eines jeden
            Newsletters oder können sonst eine der oben angegebenen
            Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter
            enthalten einen sogenannte "web-beacon“, d.h., eine pixelgroße Datei, die
            beim Öffnen des Newsletters von unserem Server, bzw., sofern wir einen
            Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im
            Rahmen dieses Abrufs werden zunächst technische Informationen, wie
            Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und
            der Zeitpunkt des Abrufs, erhoben. Diese Informationen werden zur
            technischen Verbesserung unseres Newsletters anhand der technischen Daten
            oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte
            (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten
            genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob die
            Newsletter geöffnet werden, wann sie geöffnet werden und welche Links
            geklickt werden. Diese Informationen werden den einzelnen
            Newsletterempfängern zugeordnet und in deren Profilen bis zu deren
            Löschung gespeichert. Die Auswertungen dienen uns dazu, die
            Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie
            anzupassen oder unterschiedliche Inhalte entsprechend den Interessen
            unserer Nutzer zu versenden. Die Messung der Öffnungsraten und der
            Klickraten sowie Speicherung der Messergebnisse in den Profilen der Nutzer.
          </li>
          <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO).</li>

        </ul>
        <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
          Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon, Post oder
          Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
        </p>
        <p>
          Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
          widerrufen oder der werblichen Kommunikation jederzeit zu widersprechen.
        </p>
        <p>
          Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der bisherigen
          Berechtigung erforderlichen Daten zur Kontaktaufnahme oder Zusendung bis zu
          drei Jahre nach Ablauf des Jahres des Widerrufs oder Widerspruchs auf der
          Grundlage unserer berechtigten Interessen. Die Verarbeitung dieser Daten ist
          auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Auf der
          Grundlage des berechtigten Interesses, den Widerruf bzw. Widerspruch der
          Nutzer dauerhaft zu beachten, speichern wir ferner die zur Vermeidung einer
          erneuten Kontaktaufnahme erforderlichen Daten (z.B. je nach
          Kommunikationskanal die E-Mail-Adresse, Telefonnummer, Name).
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail
            oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
        <p>
          Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
          Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
          Interessen oder demographische Informationen zu den Besuchern, wie z.B. das
          Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der
          Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
          Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
          werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen,
          welche Bereiche der Optimierung bedürfen.{" "}
        </p>
        <p>
          Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
          unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile
          zu testen und optimieren.
        </p>
        <p>
          Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile,
          d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
          Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
          diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
          besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie
          der verwendete Browser, das verwendete Computersystem sowie Angaben zu
          Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns
          gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
          einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
          wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
          IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
          Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
          z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir
          als auch die Anbieter der eingesetzten Software kennen nicht die
          tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen
          Verfahren in deren Profilen gespeicherten Angaben.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
            Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
            nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
          </li>
          <li>
            <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
            IP-Adresse).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Google Optimize: </strong>Software zur Analyse und Optimierung von
            Onlineangeboten auf Grundlage von Feedbackfunktionen sowie pseudonym
            durchgeführten Messungen und Analysen des Nutzerverhaltens, zu denen
            insbesondere A/B-Tests (Messung der Beliebtheit und Nutzerfreundlichkeit
            von unterschiedlichen Inhalten und Funktionen), Messung von Klickstrecken
            und der Interaktion mit Inhalten und Funktionen des Onlineangebotes
            gehören können; <strong>Dienstanbieter:</strong> Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://optimize.google.com" target="_blank" rel="noreferrer">
              https://optimize.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
            <a
              href="https://business.safety.google/adsprocessorterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Standardvertragsklauseln:</strong>{" "}
            <a
              href="https://business.safety.google/adsprocessorterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms
            </a>
            ; <strong>Weitere Informationen:</strong>{" "}
            <a
              href="https://privacy.google.com/businesses/adsservices"
              target="_blank" rel="noreferrer"
            >
              https://privacy.google.com/businesses/adsservices
            </a>{" "}
            (Arten der Verarbeitung sowie der verarbeiteten Daten).
          </li>
          <li>
            <strong>Google Tag Manager: </strong>Google Tag Manager ist eine Lösung,
            mit der wir sog. Website-Tags über eine Oberfläche verwalten und so andere
            Dienste in unser Onlineangebot einbinden können (hierzu wird auf weitere
            Angaben in dieser Datenschutzerklärung verwiesen). Mit dem Tag Manager
            selbst (welches die Tags implementiert) werden daher z. B. noch keine
            Profile der Nutzer erstellt oder Cookies gespeichert. Google erfährt
            lediglich die IP-Adresse des Nutzers, was notwendig ist, um den Google Tag
            Manager auszuführen; <strong>Dienstanbieter:</strong> Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
              https://marketingplatform.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>
            <a
              href="https://business.safety.google/adsprocessorterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Standardvertragsklauseln:</strong>{" "}
            <a
              href="https://business.safety.google/adsprocessorterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms
            </a>
            .
          </li>
          <li>
            <strong>Google Analytics 4: </strong>Wir verwenden Google Analytics zur
            Messung und Analyse der Nutzung unseres Onlineangebotes auf der Grundlage
            einer pseudonymen Nutzeridentifikationsnummer. Diese Identifikationsnummer
            enthält keine eindeutigen Daten, wie Namen oder E-Mail-Adressen. Sie dient
            dazu, Analyseinformationen einem Endgerät zuzuordnen, um zu erkennen,
            welche Inhalte die Nutzer innerhalb eines oder verschiedener
            Nutzungsvorgänge aufgerufen haben, welche Suchbegriffe sie verwendet
            haben, diese erneut aufgerufen haben oder mit unserem Onlineangebot
            interagiert haben. Ebenso werden der Zeitpunkt der Nutzung und deren Dauer
            gespeichert, sowie die Quellen der Nutzer, die auf unser Onlineangebot
            verweisen und technische Aspekte ihrer Endgeräte und Browser. Dabei werden
            pseudonyme Profile von Nutzern mit Informationen aus der Nutzung
            verschiedener Geräte erstellt, wobei Cookies eingesetzt werden können.
            Google Analytics protokolliert und speichert keine individuellen
            IP-Adressen für EU-Nutzer. Analytics stellt jedoch grobe geografische
            Standortdaten bereit, indem es die folgenden Metadaten von IP-Adressen
            ableitet: Stadt (und der abgeleitete Breiten- und Längengrad der Stadt),
            Kontinent, Land, Region, Subkontinent (und ID-basierte Gegenstücke). Beim
            EU-Datenverkehr werden die IP-Adressdaten ausschließlich für diese
            Ableitung von Geolokalisierungsdaten verwendet, bevor sie sofort gelöscht
            werden. Sie werden nicht protokolliert, sind nicht zugänglich und werden
            nicht für weitere Verwendungszwecke genutzt. Wenn Google Analytics
            Messdaten sammelt, werden alle IP-Abfragen auf EU-basierten Servern
            durchgeführt, bevor der Verkehr zur Verarbeitung an Analytics-Server
            weitergeleitet wird; <strong>Dienstanbieter:</strong> Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
              S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>{" "}
            <a
              href="https://marketingplatform.google.com/intl/de/about/analytics/"
              target="_blank" rel="noreferrer"
            >
              https://marketingplatform.google.com/intl/de/about/analytics/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
            <a
              href="https://business.safety.google/adsprocessorterms/"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms/
            </a>
            ; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
            <span className=""> EU-US Data Privacy Framework (DPF), </span>
            Standardvertragsklauseln (
            <a
              href="https://business.safety.google/adsprocessorterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms
            </a>
            ); <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
            <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer">
              https://adssettings.google.com/authenticated
            </a>
            ; <strong>Weitere Informationen:</strong>{" "}
            <a
              href="https://privacy.google.com/businesses/adsservices"
              target="_blank" rel="noreferrer"
            >
              https://privacy.google.com/businesses/adsservices
            </a>{" "}
            (Arten der Verarbeitung sowie der verarbeiteten Daten).
          </li>
        </ul>
        <h2 id="m264">Onlinemarketing</h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
          worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
          werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet)
          anhand potentieller Interessen der Nutzer sowie die Messung ihrer
          Effektivität fallen kann.{" "}
        </p>
        <p>
          Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
          Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt,
          mittels derer die für die Darstellung der vorgenannten Inhalte relevante
          Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können z.B.
          betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
          Kommunikationspartner und technische Angaben, wie der verwendete Browser,
          das verwendete Computersystem sowie Angaben zu Nutzungszeiten und genutzten
          Funktionen gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten
          eingewilligt haben, können auch diese verarbeitet werden.
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
          wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung
          durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im
          Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
          E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
          auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche
          Identität der Nutzer, sondern nur die in deren Profilen gespeicherten
          Angaben.
        </p>
        <p>
          Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels
          ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch
          auf anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen,
          ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als auch
          mit weiteren Daten ergänzt und auf dem Server des
          Onlinemarketingverfahrensanbieters gespeichert werden.
        </p>
        <p>
          Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der
          Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
          Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der
          Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu
          beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch
          Einwilligung im Rahmen der Registrierung, treffen können.
        </p>
        <p>
          Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen
          über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
          sogenannter Konversionsmessungen prüfen, welche unserer
          Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
          z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine
          zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
        </p>
        <p>
          Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
          verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
            Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
            interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
            Marketing; Profile mit nutzerbezogenen Informationen (Erstellen von
            Nutzerprofilen); Konversionsmessung (Messung der Effektivität von
            Marketingmaßnahmen).
          </li>
          <li>
            <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
            IP-Adresse).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
            a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
          <li>
            <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die
            Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
            angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine
            explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
            Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers
            abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
            eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
            Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
            angeboten werden: a) Europa:{" "}
            <a href="https://www.youronlinechoices.eu" target="_blank" rel="noreferrer">
              https://www.youronlinechoices.eu
            </a>
            . b) Kanada:{" "}
            <a href="https://www.youradchoices.ca/choices" target="_blank" rel="noreferrer">
              https://www.youradchoices.ca/choices
            </a>
            . c) USA:{" "}
            <a href="https://www.aboutads.info/choices" target="_blank" rel="noreferrer">
              https://www.aboutads.info/choices
            </a>
            . d) Gebietsübergreifend:{" "}
            <a href="https://optout.aboutads.info" target="_blank" rel="noreferrer">
              https://optout.aboutads.info
            </a>
            .
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Google Ads und Konversionsmessung: </strong>
            Online-Marketing-Verfahren zum Zwecke der Platzierung von Inhalten und
            Anzeigen innerhalb des Werbenetzwerks des Diensteanbieters (z. B. in
            Suchergebnissen, in Videos, auf Webseiten usw.), so dass sie Nutzern
            angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben.
            Darüber hinaus messen wir die Konversion der Anzeigen, d.h. ob die Nutzer
            sie zum Anlass genommen haben, mit den Anzeigen zu interagieren und die
            beworbenen Angebote zu nutzen (sog. Conversion). Wir erhalten jedoch nur
            anonyme Informationen und keine persönlichen Informationen über einzelne
            Nutzer; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
            House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong>{" "}
            Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO), Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
              https://marketingplatform.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der
            verarbeiteten Daten:{" "}
            <a
              href="https://privacy.google.com/businesses/adsservices"
              target="_blank" rel="noreferrer"
            >
              https://privacy.google.com/businesses/adsservices
            </a>
            ; Datenverarbeitungsbedingungen zwischen Verantwortlichen und
            Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
            <a
              href="https://business.safety.google/adscontrollerterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adscontrollerterms
            </a>
            .
          </li>
          <li>
            <strong>Google Adsense mit personalisierten Anzeigen: </strong>Wir nutzen
            den Dienst Google Adsense mit personalisierten Anzeigen, mit dessen Hilfe
            innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden und wir für
            deren Einblendung oder sonstige Nutzung eine Entlohnung erhalten;{" "}
            <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong>{" "}
            Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
              https://marketingplatform.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der
            verarbeiteten Daten:{" "}
            <a
              href="https://privacy.google.com/businesses/adsservices"
              target="_blank" rel="noreferrer"
            >
              https://privacy.google.com/businesses/adsservices
            </a>
            ; Datenverarbeitungsbedingungen für Google Werbeprodukte: Informationen zu
            den Diensten Datenverarbeitungsbedingungen zwischen Verantwortlichen und
            Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
            <a
              href="https://business.safety.google/adscontrollerterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adscontrollerterms
            </a>
            .
          </li>
          <li>
            <strong>Google Adsense mit nicht-personalisierten Anzeigen: </strong>Wir
            nutzen den Dienst Google Adsense mit nicht-personalisierten Anzeigen, mit
            dessen Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet
            werden und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung
            erhalten; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
            House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong>{" "}
            Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
              https://marketingplatform.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Weitere Informationen:</strong> Types of processing and data
            processed:{" "}
            <a
              href="https://privacy.google.com/businesses/adsservices"
              target="_blank" rel="noreferrer"
            >
              https://privacy.google.com/businesses/adsservices
            </a>
            ; Google Ads Controller-Controller Data Protection Terms and standard
            contractual clauses for data transfers to third countries:{" "}
            <a
              href="https://business.safety.google/adscontrollerterms"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adscontrollerterms
            </a>
            .
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten
          in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu
          kommunizieren oder um Informationen über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
          Europäischen Union verarbeitet werden können. Hierdurch können sich für die
          Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
          erschwert werden könnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
          für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
          Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
          Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
          verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
          Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
          Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
          gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind
          und bei diesen eingeloggt sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
          Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den
          Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
          Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
          ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
          können Sie sich an uns wenden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
            IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular);
            Marketing.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Instagram: </strong>Soziales Netzwerk;{" "}
            <strong>Dienstanbieter:</strong> Meta Platforms Irland Limited, 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
              https://www.instagram.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://instagram.com/about/legal/privacy" target="_blank" rel="noreferrer">
              https://instagram.com/about/legal/privacy
            </a>
            .
          </li>
          <li>
            <strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen Netzwerks
            Facebook - Wir sind gemeinsam mit Meta Platforms Ireland Limited für die
            Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der Besucher
            unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu diesen Daten
            gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen
            oder mit denen sie interagieren, oder die von ihnen vorgenommenen
            Handlungen (siehe unter „Von dir und anderen getätigte und bereitgestellte
            Dinge“ in der Facebook-Datenrichtlinie:{" "}
            <a href="https://www.facebook.com/policy" target="_blank" rel="noreferrer">
              https://www.facebook.com/policy
            </a>
            ), sowie Informationen über die von den Nutzern genutzten Geräte (z. B.
            IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
            Cookie-Daten; siehe unter „Geräteinformationen“ in der
            Facebook-Datenrichtlinie:{" "}
            <a href="https://www.facebook.com/policy" target="_blank" rel="noreferrer">
              https://www.facebook.com/policy
            </a>
            ). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese
            Informationen?“ erläutert, erhebt und verwendet Facebook Informationen
            auch, um Analysedienste, so genannte "Seiten-Insights", für
            Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
            erhalten, wie Personen mit ihren Seiten und mit den mit ihnen verbundenen
            Inhalten interagieren. Wir haben mit Facebook eine spezielle Vereinbarung
            abgeschlossen ("Informationen zu Seiten-Insights",{" "}
            <a
              href="https://www.facebook.com/legal/terms/page_controller_addendum"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
            ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook
            beachten muss und in der Facebook sich bereit erklärt hat die
            Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder
            Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer
            (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei
            zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit
            Facebook nicht eingeschränkt. Weitere Hinweise finden sich in den
            "Informationen zu Seiten-Insights" (
            <a
              href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            ); <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4
            Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              https://www.facebook.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
              https://www.facebook.com/about/privacy
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000GnywAAC&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000GnywAAC&amp;status=Active
            </a>
            ; <strong>Standardvertragsklauseln:</strong>{" "}
            <a
              href="https://www.facebook.com/legal/EU_data_transfer_addendum"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>
            ; <strong>Weitere Informationen:</strong> Vereinbarung gemeinsamer
            Verantwortlichkeit:{" "}
            <a
              href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
              target="_blank" rel="noreferrer"
            >
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            . Die gemeinsame Verantwortlichkeit beschränkt sich auf die Erhebung durch
            und Übermittlung von Daten an Meta Platforms Ireland Limited, ein
            Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten liegt
            in der alleinigen Verantwortung von Meta Platforms Ireland Limited, was
            insbesondere die Übermittlung der Daten an die Muttergesellschaft Meta
            Platforms, Inc. in den USA betrifft (auf der Grundlage der zwischen Meta
            Platforms Ireland Limited und Meta Platforms, Inc. geschlossenen
            Standardvertragsklauseln).
          </li>
        </ul>
        <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
        <p>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die
          von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als
          "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
          Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich bezeichnet
          als "Inhalte”).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die
          IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte
          nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
          Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns,
          nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse
          lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können
          ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
          "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten
          dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können
          ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
          anderem technische Informationen zum Browser und zum Betriebssystem, zu
          verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung
          unseres Onlineangebotes enthalten als auch mit solchen Informationen aus
          anderen Quellen verbunden werden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
            Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
            Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
            wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
            Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung und
            Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem Anbieter
            der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt, damit die
            Schriftarten im Browser des Nutzers zur Verfügung gestellt werden können.
            Darüber hinaus werden technische Daten (Spracheinstellungen,
            Bildschirmauflösung, Betriebssystem, verwendete Hardware) übermittelt, die
            für die Bereitstellung der Schriften in Abhängigkeit von den verwendeten
            Geräten und der technischen Umgebung notwendig sind. Diese Daten können
            auf einem Server des Anbieters der Schriftarten in den USA verarbeitet
            werden - Beim Besuch unseres Onlineangebotes senden die Browser der Nutzer
            ihre Browser HTTP-Anfragen an die Google Fonts Web API (d.h. eine
            Softwareschnittstelle für den Abruf der Schriftarten). Die Google Fonts
            Web API stellt den Nutzern die Cascading Style Sheets (CSS) von Google
            Fonts und danach die in der CCS angegebenen Schriftarten zur Verfügung. Zu
            diesen HTTP-Anfragen gehören (1) die vom jeweiligen Nutzer für den Zugriff
            auf das Internet verwendete IP-Adresse, (2) die angeforderte URL auf dem
            Google-Server und (3) die HTTP-Header, einschließlich des User-Agents, der
            die Browser- und Betriebssystemversionen der Websitebesucher beschreibt,
            sowie die Verweis-URL (d.h. die Webseite, auf der die Google-Schriftart
            angezeigt werden soll). IP-Adressen werden weder auf Google-Servern
            protokolliert noch gespeichert und sie werden nicht analysiert. Die Google
            Fonts Web API protokolliert Details der HTTP-Anfragen (angeforderte URL,
            User-Agent und Verweis-URL). Der Zugriff auf diese Daten ist eingeschränkt
            und streng kontrolliert. Die angeforderte URL identifiziert die
            Schriftfamilien, für die der Nutzer Schriftarten laden möchte. Diese Daten
            werden protokolliert, damit Google bestimmen kann, wie oft eine bestimmte
            Schriftfamilie angefordert wird. Bei der Google Fonts Web API muss der
            User-Agent die Schriftart anpassen, die für den jeweiligen Browsertyp
            generiert wird. Der User-Agent wird in erster Linie zum Debugging
            protokolliert und verwendet, um aggregierte Nutzungsstatistiken zu
            generieren, mit denen die Beliebtheit von Schriftfamilien gemessen wird.
            Diese zusammengefassten Nutzungsstatistiken werden auf der Seite
            „Analysen“ von Google Fonts veröffentlicht. Schließlich wird die
            Verweis-URL protokolliert, sodass die Daten für die Wartung der Produktion
            verwendet und ein aggregierter Bericht zu den Top-Integrationen basierend
            auf der Anzahl der Schriftartenanfragen generiert werden kann. Google
            verwendet laut eigener Auskunft keine der von Google Fonts erfassten
            Informationen, um Profile von Endnutzern zu erstellen oder zielgerichtete
            Anzeigen zu schalten; <strong>Dienstanbieter:</strong> Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland;{" "}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
            S. 1 lit. f) DSGVO); <strong>Website:</strong>{" "}
            <a href="https://fonts.google.com/" target="_blank" rel="noreferrer">
              https://fonts.google.com/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Privacy Framework (DPF):</strong>{" "}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
              target="_blank" rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ; <strong>Weitere Informationen:</strong>{" "}
            <a
              href="https://developers.google.com/fonts/faq/privacy?hl=de"
              target="_blank" rel="noreferrer"
            >
              https://developers.google.com/fonts/faq/privacy?hl=de
            </a>
            .
          </li>

          <li>
            <p>
              <strong>Cookiebot</strong>
              <br /> Wir nutzen der Zustimmungsverwaltungsdienst Cookiebot, der
              Usercentrics A/S, Havnegade 39, 1058 Kopenhagen, Dänemark (Usercentrics).
              Dies ermöglicht uns die Einwilligung der Websitenutzer zur Datenverarbeitung
              einzuholen und zu verwalten. Die Verarbeitung ist zur Erfüllung einer
              rechtlichen Verpflichtung erforderlich (Art. 7 Abs. 1DSGVO), der wir
              unterliegen (Art. 6 Abs. 1 S. 1 lit. c DSGVO). Dafür werden mit Hilfe von
              Cookies folgende Daten verarbeitet:
            </p>{" "}
            <p>
              Ihre IP-Adresse (die letzten drei Ziffern werden auf ‚0’ gesetzt). Datum und
              Uhrzeit der Zustimmung. Browserinformationen URL, von der die Zustimmung
              gesendet wurde. Ein anonymer, zufälliger und verschlüsselter Schlüssel Ihr
              Einwilligungsstatus des Endnutzers, als Nachweis der Zustimmung
            </p>{" "}
            <p>
              Der Schlüssel und Zustimmungsstatus werden für 12 Monate im Browser mit
              Hilfe des Cookies „CookieConsent“ gespeichert. Damit bleibt Ihre
              Cookie-Präferenz für nachfolgende Seitenanfragen erhalten. Mit Hilfe des
              Schlüssels kann ihre Zustimmung nachgewiesen und nachvollzogen werden.
            </p>{" "}
            <p>
              Wenn Sie die Service-Funktion „Sammelzustimmung“ aktivieren, um die
              Zustimmung für mehrere Webseiten durch eine einzige Endnutzerzustimmung zu
              aktivieren, speichert der Dienst zusätzlich eine separate, zufällige,
              eindeutige ID mit Ihrer Zustimmung. Wenn alle folgenden Kriterien erfüllt
              sind, wird dieser Schlüssel im Cookie des Drittanbieters
              „CookieConsentBulkTicket“ in Ihrem Browser in verschlüsselter Form
              gespeichert: Sie aktivieren die Sammelzustimmungsfunktion in der
              Dienstkonfiguration. Sie lassen Cookies von Drittanbietern über
              Browsereinstellungen zu. Sie haben „Nicht verfolgen“ über die
              Browsereinstellungen deaktiviert. Sie akzeptieren alle oder wenigstens
              bestimmte Arten von Cookies, wenn Sie die Zustimmung erteilen.
            </p>{" "}
            <p>
              Die Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht
              gewährleistet.
            </p>{" "}
            <p>
              Usercentrics ist Empfänger Ihrer personenbezogenen Daten und als
              Auftragsverarbeiter für uns tätig.
            </p>{" "}
            <p>
              Die Verarbeitung findet in der Europäischen Union statt. Weitere
              Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber
              Usercentrics finden Sie unter: https://www.cookiebot.com/de/privacy-policy/
            </p>{" "}
            <p>
              Ihre personenbezogenen Daten werden fortlaufend nach 12 Monaten oder
              unmittelbar nach der Kündigung des Vertrages zwischen uns und Usercentrics
              gelöscht.
            </p>{" "}
            <p>
              Bitte beachten Sie unsere generellen Ausführungen über die Löschung und
              Deaktivierung von Cookies oben.
            </p>
          </li>
        </ul>


        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung
          zu informieren. Wir passen die Datenschutzerklärung an, sobald die
          Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
          machen. Wir informieren Sie, sobald durch die Änderungen eine
          Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
          individuelle Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen
          von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die
          Adressen sich über die Zeit ändern können und bitten die Angaben vor
          Kontaktaufnahme zu prüfen.
        </p>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
          Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
          Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
          Verständnis dienen.
        </p>
        <ul className="glossary">
          <li>
            <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch als
            "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit dem die
            Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im
            Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten,
            auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf
            der Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen,
            ob die von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich
            waren.{" "}
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
            alle Informationen, die sich auf eine identifizierte oder identifizierbare
            natürliche Person (im Folgenden "betroffene Person“) beziehen; als
            identifizierbar wird eine natürliche Person angesehen, die direkt oder
            indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
            zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B.
            Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
            werden kann, die Ausdruck der physischen, physiologischen, genetischen,
            psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.{" "}
          </li>
          <li>
            <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
            Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw. kurz
            "Profilen" umfasst jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese personenbezogenen
            Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
            eine natürliche Person beziehen (je nach Art der Profilbildung können dazu
            unterschiedliche Informationen betreffend die Demographie, Verhalten und
            Interessen, wie z.B. die Interaktion mit Webseiten und deren Inhalten,
            etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
            Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
            einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
            häufig Cookies und Web-Beacons eingesetzt.{" "}
          </li>
          <li>
            <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
            Analytics bezeichnet) dient der Auswertung der Besucherströme eines
            Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
            bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
            Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
            welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
            sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite
            besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
            Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
            eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
            Analysen zur Nutzung eines Onlineangebotes zu erhalten.{" "}
          </li>
          <li>
            <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten
            von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann.
            Im Regelfall werden im Hinblick auf die genutzten Onlineangebote
            Verhaltens- und Interessensinformationen in Cookies oder auf Servern der
            Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling).
            Diese Informationen können anschließend z.B. eingesetzt werden, um den
            Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen
            entsprechen.{" "}
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
            der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{" "}
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
            Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
            Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
            reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
            Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
          </li>
        </ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
          </a>
        </p>
      </>

    </div>
  )
}

export default DataStatementContent