import React from 'react'
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import DrawerContent from './DrawerContent'
// import Typography from '@material-ui/core/Typography';
import logo from '../logo_red_transp.png';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

function ResponsiveDrawer({ mobileOpen, handleDrawerToggle, settings, setSettings, loginStatus, playerList, expandMenuEffectStatus }) {

  // const { window } = props;  
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      padding: theme.spacing(2, 2, 2),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerBody: {
      padding: theme.spacing(0, 2, 2, 2),
      margin: theme.spacing(0, 0, 50, 0),
    },
    divider: {
      margin: theme.spacing(3, 0, 4, 0),
    },
    logoBox: {
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      height: "30%",
      width: "30%",
    }
  }));
  const classes = useStyles();

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {/* <div className={`${classes.toolbar} ${classes.title}`} ><Typography variant="h6" >Einstellungen</Typography></div>
            <Divider /> */}
            <div className={classes.drawerBody}>
              <DrawerContent settings={settings} setSettings={setSettings} loginStatus={loginStatus} playerList={playerList} expandMenuEffectStatus={expandMenuEffectStatus} />
              <div className={classes.divider}>
                <Divider />
              </div>
              <div className={classes.logoBox}>
                <img src={logo} alt="Kickzteam" className={classes.logo}></img>
              </div>
            </div>
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer
