import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { fetchTeam, postTeam } from '../restCalls'
import { initialTeam } from '../util'

function OptimizeButton({ settings, setTeam, loadStatus, setLoadStatus, loginStatus, setInstructionVisible, setGenericAlertStatus, increaseOptiCounter }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%"
    },
    btn: {
      margin: theme.spacing(0, 0, 0),
      width: "100%",
      height: "100%"
    },
  }))
  const classes = useStyles()

  ///////////////////////////////
  //FUNKTIONS
  ///////////////////////////////

  //sleep
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  //Optimize button function
  const optimizeTeam = async () => {
    //console.log(process.env)

    // Google Analytics
    if (loginStatus.pro) {
      //window.gtag("event", "select_content", { content_type: "optimize_click_pro" })
    } else {
      //window.gtag("event", "select_content", { content_type: "optimize_click" })
    }

    // steering params
    var finished = false
    var elapsed_time = 0
    const initialStepSize = 3000
    const finalStepSize = 500
    var step_size = initialStepSize
    const time_limit = 10000

    // set status
    setTeam(initialTeam)
    setInstructionVisible(false)
    setLoadStatus({ fetched: false, progress: 0 })
    increaseOptiCounter()

    // post team
    const { postStatus, teamId } = await postTeam(settings, loginStatus)
    if (postStatus !== 200) {
      setGenericAlertStatus({ open: true, title: "Fehler", body: "Die Optimierung konnte nicht gestartet werden. Bitte versuche es später wieder.", button: "Ok" })
      setLoadStatus({ fetched: true, progress: 0 })
      setTeam(initialTeam)
      finished = true
    }

    while (!finished) {
      //console.log(elapsed_time)
      //console.log(teamId)
      //console.log(loadStatus.fetched)

      const { status, body } = await fetchTeam(teamId)

      //console.log(status)
      //console.log(body)

      if (status === 200) {
        if (body.calculation_progress < 0.6) {
          if (elapsed_time < time_limit) {
            // We are still within timelimit but calcs are not yet ready
            setLoadStatus({ fetched: false, progress: body.calculation_progress })
            //console.log("200 Intime")
          } else {
            // Calcs are not yet ready but we are out of timelimit
            // we stop, but only set the team if it was returned
            setLoadStatus({ fetched: true, progress: body.calculation_progress })
            finished = true
            if ('team' in body) {
              setTeam({ teamId: teamId, players: body.team, totalMarketValue: body.totalMarketValue, stats: body.stats })
              //console.log("200 Overtime")
            } else {
              setTeam({ teamId: teamId, players: [], totalMarketValue: 0, stats: {} })
              setGenericAlertStatus({ open: true, title: "Fehler", body: "Es konnte kein gültiges Team ermittelt werden. Bitte versuche andere Einstellungen.", button: "Ok" })
            }
          }
        } else {
          // calc threshold was reached
          // we stop, but only set the team if it was returned
          setLoadStatus({ fetched: true, progress: body.calculation_progress })
          finished = true
          if ('team' in body) {
            setTeam({ teamId: teamId, players: body.team, totalMarketValue: body.totalMarketValue, stats: body.stats })
            //console.log("200 progress finished")
          } else {
            setTeam({ teamId: teamId, players: [], totalMarketValue: 0, stats: {} })
            setGenericAlertStatus({ open: true, title: "Fehler", body: "Es konnte kein gültiges Team ermittelt werden. Bitte versuche andere Einstellungen.", button: "Ok" })
          }
        }
      } else if (status === 404) {
        if (elapsed_time < time_limit) {
          // team was not created in DB but we are within timelimit
          setLoadStatus({ fetched: false, progress: elapsed_time / time_limit })
          //console.log("404 InTime")
        } else {
          // team was not created in DB and we are out of time -> abort
          setLoadStatus({ fetched: true, progress: elapsed_time / time_limit })
          setTeam({ teamId: teamId, players: [], totalMarketValue: 0, stats: {} })
          finished = true
          setGenericAlertStatus({ open: true, title: "Fehler", body: "Die Optimierung konnte nicht abgeschlossen werden. Bitte versuche es später wieder.", button: "Ok" })
          //console.log("404 Overtime")
        }
      } else {
        if (elapsed_time < time_limit) {
          // We got an unknown error but we are within timelimit -> go on
          setLoadStatus({ fetched: false, progress: elapsed_time / time_limit })
          //console.log("Other Error InTime")
        } else {
          // We got an unknown error and out of time -> abort
          setLoadStatus({ fetched: true, progress: elapsed_time / time_limit })
          setTeam({ teamId: teamId, players: [], totalMarketValue: 0, stats: {} })
          finished = true
          setGenericAlertStatus({ open: true, title: "Fehler", body: "Die Optimierung konnte nicht abgeschlossen werden. Bitte versuche es später wieder.", button: "Ok" })
          //console.log("Other Error: Out of time")
        }
      }
      await sleep(step_size)
      elapsed_time += step_size
      step_size = finalStepSize
    }
  }

  return (
    <div>
      <Button variant="contained" color="primary" className={classes.btn} onClick={optimizeTeam} >
        {loadStatus.fetched ? "Optimieren" : <CircularProgress color="inherit" size="20px" />}
      </Button>
    </div>
  )
}

export default OptimizeButton
