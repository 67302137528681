import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import Agb from './Agb'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { getUserInfo } from '../authScripts'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchPlus } from '../restCalls'

function Payment({ loginStatus, setLoginStatus, agbCheckStatus, setAgbCheckStatus, setGenericAlertStatus }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    links: {
      textDecoration: "underline",
      color: "inherit",
      cursor: "pointer",
    },
    ppLoading: {
      height: "40px"
    },
  }))
  const classes = useStyles()

  ///////////////////////////////
  //FUNKTIONS
  ///////////////////////////////


  // setting the reference to the check status for use in PayPal Button provider
  const agbCheckStatusRef = useRef(agbCheckStatus)
  useEffect(() => {
    agbCheckStatusRef.current = agbCheckStatus
  })

  // create the paypal order
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: process.env.REACT_APP_PRO_PRICE_EURO,
            currency_code: "EUR",
            breakdown: {
              item_total: {
                value: process.env.REACT_APP_PRO_PRICE_EURO,
                currency_code: "EUR",
              }
            }
          },
          custom_id: loginStatus.userId,
          items: [
            {
              name: "Kickzteam Pro-Ticket Sommer 2024",
              unit_amount: {
                value: process.env.REACT_APP_PRO_PRICE_EURO,
                currency_code: "EUR",
              },
              quantity: "1",
              category: "DIGITAL_GOODS"
            }
          ]
        },
      ],
      application_context: {
        brand_name: 'Kickzteam',
        shipping_preference: "NO_SHIPPING"
      }
    })
  }

  //capture the approval and send to own API
  const handleApproval = async (data, actions) => {
    // call the Paypal capture API
    const captureResponsePromise = actions.order.capture()
    const captureResponse = await captureResponsePromise
    //console.log(captureResponse)

    //if capture succeeded post it to our API
    if (captureResponse.status === "COMPLETED") {
      const res = postPaymentConfirmation(captureResponse)
      // if approved set status to pro
      if ((await res)?.status === 200) {
        getUserInfo(loginStatus, setLoginStatus)
        // Google Analytics
        //window.gtag('event', 'conversion', { 'send_to': 'AW-404729103/7K83CJOY0tkCEI_a_sAB', 'transaction_id': loginStatus.userId + process.env.REACT_APP_CURRENT_SUBSCRIPTION_NAME })
        //window.gtag('event', 'purchase', { value: 9.95, currency: 'EUR', 'transaction_id': loginStatus.userId + process.env.REACT_APP_CURRENT_SUBSCRIPTION_NAME })
        setGenericAlertStatus({ open: true, title: "Erfolgreiche Zahlung", body: "Herzlichen Glückwunsch! Viel Erfolg mit deiner Pro-Version!", button: "Ok" })
      }
      else {
        // payment went through but something was wrong with our API -> Webhook will cover
        // Google Analytics
        //window.gtag('event', 'conversion', { 'send_to': 'AW-404729103/7K83CJOY0tkCEI_a_sAB', 'transaction_id': loginStatus.userId + process.env.REACT_APP_CURRENT_SUBSCRIPTION_NAME })
        //window.gtag('event', 'purchase', { value: 9.95, currency: 'EUR', 'transaction_id': loginStatus.userId + process.env.REACT_APP_CURRENT_SUBSCRIPTION_NAME })
        setGenericAlertStatus({ open: true, title: "Erfolgreiche Zahlung", body: "Herzlichen Glückwunsch! Deine Pro-Version wird innerhalb der nächsten Minuten aktiviert.", button: "Ok" })
      }
    } else {
      setGenericAlertStatus({ open: true, title: "Fehler", body: "Die Zahlung konnte nicht erfolgreich durchgeführt werden.", button: "Ok" })
    }

    return captureResponsePromise
  }

  // post the processed payment detail to the API
  const postPaymentConfirmation = async (orderDetail) => {

    const res = await fetchPlus(`${process.env.REACT_APP_API_HOST}/exp/pro/payment`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${loginStatus.idToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(
          {
            orderId: orderDetail.id,
          }
        )
      }, 3)
    return res
  }

  // Show message if AGB not approved
  const checkAgb = (data, actions) => {
    if (!(agbCheckStatusRef.current === true)) {
      setGenericAlertStatus({ open: true, title: "Hinweis", body: "Bitte bestätige die AGB.", button: "Ok" })
    }
  }

  function PpLoading() {
    const [{ isPending }] = usePayPalScriptReducer()
    return isPending ? <div className="ppLoading" ><CircularProgress /></div> : null
  }
  function PpFailed() {
    const [{ isRejected }] = usePayPalScriptReducer()
    return isRejected ? <div className="ppLoading" >Payment-Optionen konnten nicht geladen werden. Bitte Seite neu laden.</div> : null
  }


  // Agb panel
  const [agbStatus, setAgbStatus] = useState(false)


  ///////////////////////////////
  // RETURN
  ///////////////////////////////

  return (
    <div>
      <Agb agbStatus={agbStatus} setAgbStatus={setAgbStatus}></Agb>

      <PayPalButtons disabled={!agbCheckStatus} style={{ color: "gold", shape: "rect", layout: "vertical" }}
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => handleApproval(data, actions)}
        onClick={(data, actions) => checkAgb(data, actions)}
        onShippingChange={(data, actions) => { return actions.resolve() }}
        forceReRender={[agbCheckStatus]}
      />
      <PpLoading /><PpFailed />

      <FormControlLabel
        control={
          <Checkbox
            checked={agbCheckStatus}
            size="small"
            color="primary"
            onChange={(event) => { setAgbCheckStatus(event.target.checked) }}
          />
        }
        label={<div >Ich stimme den <span className={classes.links} onClick={() => { setAgbStatus(true) }}>AGB</span> zu</div>}
      />
    </div>
  )
}

export default Payment
