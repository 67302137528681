import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function GenericAlert({ genericAlertStatus, setGenericAlertStatus }) {
  return (
    <div>
      <Dialog
        open={genericAlertStatus.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{genericAlertStatus.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {genericAlertStatus.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setGenericAlertStatus({ open: false, title: "", body: "", button: "" }); }} color="primary">
            {genericAlertStatus.button}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GenericAlert
