import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

function InfoTooltip({ tooltipText }) {

  const useStyles = makeStyles({
    root: {
      zIndex: "200",
      cursor: "pointer"
    },
  })
  const classes = useStyles()

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  return (

    <ClickAwayListener onClickAway={() => { setTooltipOpen(false) }}>
      <Tooltip className={classes.root} title={tooltipText} placement="top" PopperProps={{ disablePortal: true, }} onClose={() => { setTooltipOpen(false) }} open={tooltipOpen} disableFocusListener disableHoverListener disableTouchListener>
        <InfoOutlinedIcon onClick={() => { setTooltipOpen(!tooltipOpen) }} color="disabled" />
      </Tooltip>
    </ClickAwayListener>

  )
}

export default InfoTooltip
