import React from 'react'
import BasicSettings from './BasicSettings'
import TeamStructure from './TeamStructure'
import BasicSettingsSubstitutes from './BasicSettingsSubstitutes'
import PlayerSelection from './PlayerSelection'
import MarketValueLimits from './MarketValueLimits'


import { makeStyles } from '@material-ui/core/styles'

function DrawerContent({ settings, setSettings, loginStatus, playerList, expandMenuEffectStatus }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    settingsGroup: {
      margin: theme.spacing(1, 0, 1),
    },
  }))
  const classes = useStyles()

  return (
    <div className="root">
      <div className={classes.settingsGroup}>
        <BasicSettings settings={settings} setSettings={setSettings} loginStatus={loginStatus} expandMenuEffectStatus={expandMenuEffectStatus}></BasicSettings>
      </div>
      <div className={classes.settingsGroup}>
        <BasicSettingsSubstitutes settings={settings} setSettings={setSettings} loginStatus={loginStatus} expandMenuEffectStatus={expandMenuEffectStatus}></BasicSettingsSubstitutes>
      </div>
      <div className={classes.settingsGroup}>
        <TeamStructure settings={settings} setSettings={setSettings} loginStatus={loginStatus} expandMenuEffectStatus={expandMenuEffectStatus}></TeamStructure>
      </div>
      <div className={classes.settingsGroup}>
        <MarketValueLimits settings={settings} setSettings={setSettings} loginStatus={loginStatus} playerList={playerList} expandMenuEffectStatus={expandMenuEffectStatus}></MarketValueLimits>
      </div>
      <div className={classes.settingsGroup}>
        <PlayerSelection settings={settings} setSettings={setSettings} loginStatus={loginStatus} playerList={playerList} expandMenuEffectStatus={expandMenuEffectStatus}></PlayerSelection>
      </div>
    </div>
  )
}

export default DrawerContent
