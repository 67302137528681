import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Impressum({ impressumStatus, setImpressumStatus }) {
  return (
    <div>
      <Dialog
        open={impressumStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Impressum</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
              <p>Quasarfox OÜ (Limited)<br />
                Sepapaja 6<br />
                15551 Tallin</p>

              <p>Estland<br />
                e-Business Register: 16257111</p>
              <br />
              <p><strong>Vertreten durch:</strong><br />
                Dr. Kai Schatto<br /></p>

              <br />
              <p><strong>Management board:</strong><br />
                Dr. Kai Schatto</p>
              <br />
              <h3>Kontakt</h3>
              <p>Telefon: +372 627 4077<br />
                E-Mail: kickzteam@quasarfox.de</p>
              <br />
              <h3>Umsatzsteuer-ID</h3>
              <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                EE102385649</p>
              <br />
              <h3>EU-Streitschlichtung</h3>
              <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
              <br />
              <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
              <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setImpressumStatus(false); }} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Impressum
