import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from "react-router-dom"
import InfoTooltip from './InfoTooltip'

function ValueLabelComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

export default function DiscreteSliderMv({ title, badge, disabledFlag, settings, setSettings, targetValueName, tooltipText }) {


  const theme = useTheme()
  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    sliderLabel: {
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(0, 0, -1),
    },
    badgeLink: {
      textDecoration: "none",
      color: "inherit"
    },
  })
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    const newMaxMvByPosition = { ...settings.maxMvByPosition, [targetValueName]: newValue }
    setSettings({ ...settings, maxMvByPosition: newMaxMvByPosition })
  }

  function valuetext(value) {
    return value.toString() + " Mio"
  }

  const marks = [
    {
      value: 1,
      label: '1 Mio',
    },
    {
      value: 2,
      label: '2 Mio',
    },
    {
      value: 3,
      label: '3 Mio',
    },
    {
      value: 4,
      label: '4 Mio',
    },
    {
      value: 5,
      label: '5 Mio',
    },
    {
      value: 6,
      label: '6 Mio',
    },
    {
      value: 7,
      label: '7 Mio',
    },
    {
      value: 8,
      label: '8 Mio',
    },
  ]

  return (
    <div className={classes.root}>

      <div className={classes.sliderLabel}>
        <Badge badgeContent={<Link to="/profile" className={classes.badgeLink}>pro</Link>} color="secondary" invisible={!badge}>
          <Typography variant="body2" id="continuous-slider" >{title}</Typography>
        </Badge>

        <InfoTooltip tooltipText={tooltipText} />
      </div>

      <Slider
        onChange={handleChange}
        value={settings[[targetValueName]]}
        defaultValue={settings.maxMvByPosition[[targetValueName]]}
        valueLabelFormat={valuetext}
        ValueLabelComponent={ValueLabelComponent}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="auto"
        step={0.1}
        //marks={marks}
        min={1}
        max={8.5}
        disabled={disabledFlag}
      />

    </div>
  )
}