import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PlayerPanel from './PlayerPanel';


function Substitutes({ team }) {

  ///////////////////////////////
  // STYLE
  ///////////////////////////////
  const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1
    },
    positionBox: {
      width: "100%",
    },
    positionTitle: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      margin: theme.spacing(3, 0, 1),
    },
    title: {
      margin: theme.spacing(3, 0, -2),
    },
    panelContainer: {
      width: "100%",
      height: "100%",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}><Typography variant="h6" color="textPrimary" >Ersatzbank</Typography></div>
      <div className={classes.positionTitle}><Typography variant="h6" color="textSecondary" >Sturm</Typography></div>
      <div className={classes.positionBox}>
        <Grid container spacing={2} >
          {team.players.filter((p) => (p.position === "FORWARD" && p.substitute)).map((player) => (
            <Grid item xs={3} sm={6}>
              <div className={classes.panelContainer}>
                <PlayerPanel player={player}></PlayerPanel>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.positionTitle}><Typography variant="h6" color="textSecondary" >Mittelfeld</Typography></div>
      <div className={classes.positionBox}>
        <Grid container spacing={2} >
          {team.players.filter((p) => (p.position === "MIDFIELDER" && p.substitute)).map((player) => (
            <Grid item xs={3} sm={6}>
              <div className={classes.panelContainer}>
                <PlayerPanel player={player}></PlayerPanel>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.positionTitle}><Typography variant="h6" color="textSecondary" >Abwehr</Typography></div>
      <div className={classes.positionBox}>
        <Grid container spacing={2} >
          {team.players.filter((p) => (p.position === "DEFENDER" && p.substitute)).map((player) => (
            <Grid item xs={3} sm={6}>
              <div className={classes.panelContainer}>
                <PlayerPanel player={player}></PlayerPanel>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.positionTitle}><Typography variant="h6" color="textSecondary" >Tor</Typography></div>
      <div className={classes.positionBox}>
        <Grid container spacing={2} >
          {team.players.filter((p) => (p.position === "GOALKEEPER" && p.substitute)).map((player) => (
            <Grid item xs={3} sm={6}>
              <div className={classes.panelContainer}>
                <PlayerPanel player={player}></PlayerPanel>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

    </div>
  )
}

export default Substitutes
