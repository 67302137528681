import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { getOptimalPlayerCounts } from '../util'
import InfoTooltip from './InfoTooltip'
import Typography from '@material-ui/core/Typography'

function FormationSelector({ settings, setSettings }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      margin: theme.spacing(0, 0, 0),
    },
    tooltip: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      margin: theme.spacing(-0.2, 0, 0, 1),
    },
    label: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      alignContent: "flex-start",
      width: "100%",
      wrap: "nowrap",
      margin: theme.spacing(0, 0, -0.5),
    },
    caption: {
      flexGrow: 1
    },
    select: {
      width: "100%",
      margin: theme.spacing(0, 0, 0),
    },
  }))
  const classes = useStyles()


  const handleChange = (event) => {
    var desiredFormationKey = 442
    var desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 4, "MIDFIELDER": 4, "FORWARD": 2 }
    if (event.target.value === 442) {
      desiredFormationKey = 442
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 4, "MIDFIELDER": 4, "FORWARD": 2 }
    }
    if (event.target.value === 352) {
      desiredFormationKey = 352
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 3, "MIDFIELDER": 5, "FORWARD": 2 }
    }
    if (event.target.value === 433) {
      desiredFormationKey = 433
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 4, "MIDFIELDER": 3, "FORWARD": 3 }
    }
    if (event.target.value === 343) {
      desiredFormationKey = 343
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 3, "MIDFIELDER": 4, "FORWARD": 3 }
    }
    if (event.target.value === 451) {
      desiredFormationKey = 451
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 4, "MIDFIELDER": 5, "FORWARD": 1 }
    }
    if (event.target.value === 532) {
      desiredFormationKey = 532
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 5, "MIDFIELDER": 3, "FORWARD": 2 }
    }
    if (event.target.value === 541) {
      desiredFormationKey = 541
      desiredFormation = { "GOALKEEPER": 1, "DEFENDER": 5, "MIDFIELDER": 4, "FORWARD": 1 }
    }
    if (settings.autoSetPlayerCountRanges) {
      const optimalPlayerCounts = getOptimalPlayerCounts(desiredFormation, settings.desiredTeamSize)
      setSettings({
        ...settings,
        desiredFormationKey: desiredFormationKey,
        desiredFormation: desiredFormation,
        defenderRange: optimalPlayerCounts.defenderRange,
        midfielderRange: optimalPlayerCounts.midfielderRange,
        forwardRange: optimalPlayerCounts.forwardRange,
      })
    } else {
      setSettings({
        ...settings,
        desiredFormationKey: desiredFormationKey,
        desiredFormation: desiredFormation
      })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Typography className={classes.caption} variant="caption" color="textSecondary" >Aufstellung</Typography>
        <div className={classes.tooltip} ><InfoTooltip
          tooltipText="Die Aufstellung wird hier für die Auswahl der Ersatzspieler und die Darstellung verwendet. Natürlich kannst du sie später im Kicker Managerspiel frei verändern." />
        </div>
      </div>
      <Select className={classes.select}
        labelId="formation-select-label"
        id="formation-select"
        value={settings.desiredFormationKey}
        onChange={handleChange}
      >
        <MenuItem value={442}>4 - 4 - 2</MenuItem>
        <MenuItem value={352}>3 - 5 - 2</MenuItem>
        <MenuItem value={433}>4 - 3 - 3</MenuItem>
        <MenuItem value={343}>3 - 4 - 3</MenuItem>
        <MenuItem value={451}>4 - 5 - 1</MenuItem>
        <MenuItem value={532}>5 - 3 - 2</MenuItem>
        <MenuItem value={541}>5 - 4 - 1</MenuItem>
      </Select>
    </div>
  )
}

export default FormationSelector
