import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function PlayerSelector({ handleChange, playerList, loginStatus, title, value }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{ root: classes.root }}
        onChange={handleChange}
        value={value}
        multiple
        id="tags-standard"
        options={playerList}
        getOptionLabel={(option) => option.displayName}
        //defaultValue={[top100Films[2]]}
        size="small"
        disabled={!(loginStatus.loggedIn && loginStatus.pro)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={title}
            placeholder=""
          />
        )}
      />
    </div>
  )
}

export default PlayerSelector
