

//set the player position ranges to fill the squad optimally
export const getOptimalPlayerCounts = (desiredFormation, desiredTeamSize) => {

  // set reasonable constraints for positions
  if (desiredTeamSize > 10) {
    var allowedSubsPerPosition = 1
    if (desiredTeamSize > 14) allowedSubsPerPosition = 2
    return {
      defenderRange: [desiredFormation["DEFENDER"], Math.min(desiredFormation["DEFENDER"] + allowedSubsPerPosition, 7)],
      midfielderRange: [desiredFormation["MIDFIELDER"], Math.min(desiredFormation["MIDFIELDER"] + allowedSubsPerPosition, 7)],
      forwardRange: [desiredFormation["FORWARD"], Math.min(desiredFormation["FORWARD"] + allowedSubsPerPosition, 5)],
    }
  }
  else {
    return {
      defenderRange: [3, desiredFormation["DEFENDER"]],
      midfielderRange: [3, desiredFormation["MIDFIELDER"]],
      forwardRange: [1, desiredFormation["FORWARD"]],
    }
  }
}

export const initialTeam = { teamId: '', players: [], totalMarketValue: 0, stats: {} }