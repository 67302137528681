import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

function LastSeasonDialogue({ lastSeasonDialogueStatus, setLastSeasonDialogueStatus }) {
  return (
    <div>
      <Dialog
        open={lastSeasonDialogueStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Der Countdown läuft 👋</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/*             <div>
              <p><span style={{ fontWeight: 'bold' }}>Kickzteam</span> nutzt momentan noch die Daten der letzten Saison.
                Sobald das Kicker Managerspiel Interactive 2022/23 startet, werden die Daten ständig aktualisiert.</p><br />
              Bis dahin viel Spaß beim ausprobieren!
            </div> */}
            {/* <div>
              <p>Da die finalen Kicker-Marktwerte für die Saison 2023/24 noch nicht veröffentlicht sind,
                nutzt <span style={{ fontWeight: 'bold' }}>Kickzteam</span> momentan zufällige Marktwerte.
                Sobald das Kicker Managerspiel Interactive 2023/24 startet, werden alle Daten ständig aktualisiert.</p><br />
              Bis dahin viel Spaß beim ausprobieren!
            </div> */}
            {/* <div>
              <p>Danke für die fantastische Sommervorbereitung 2023 mit viel positivem Feedback, großartigen Anregungen und Ideen und unglaublichen 50000 generierten Teams! 🤯<br /><br />
                <span style={{ fontWeight: 'bold' }}>Kickzteam</span> ist in der Sommervorbereitung 2024 wieder mit aktuellen Daten für euch am Start. 🚀</p><br />
            </div> */}
            {/* <div>
              <p>Du suchst vermutlich Unterstützung für das <span style={{ fontWeight: 'bold' }}>Kicker EM-Managerspiel</span>. Leider hatte ich dieses Jahr nicht die Zeit das System an die EM anzupassen. <br /><br />
                Schau aber gerne für das <span style={{ fontWeight: 'bold' }}>Kicker Bundesliga Managerspiel 2024/25</span> wieder rein. Wie gewohnt bekommst du dann wieder massive Unterstützung mit aktuellsten Daten
                beim Basteln deines Teams. 🚀
                <br /><br />
                Bis dahin kannst du <span style={{ fontWeight: 'bold' }}>Kickzteam</span> mit den finalen Daten der letzten Bundesliga Saison testen.
              </p><br />
            </div> */}

            <div>
              <p>Bald geht's los mit dem <span style={{ fontWeight: 'bold' }}>Kicker Bundesliga Managerspiel 2024/25</span>. Pünktlich zum Start bekommst du auf <span style={{ fontWeight: 'bold' }}>Kickzteam</span> wieder massive Unterstützung
                beim Basteln deines Teams. 🚀
                <br /><br />
                Bis dahin kannst du <span style={{ fontWeight: 'bold' }}>Kickzteam</span> mit den finalen Daten der letzten Bundesliga Saison testen.
              </p><br />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setLastSeasonDialogueStatus(false) }} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LastSeasonDialogue
